/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { Pie } from "react-chartjs-2";
import { Chart } from "react-charts";
import "./index.css";

const data = {
  labels: ["Active", "Inactive"],
  datasets: [
    {
      label: "# of Votes",
      data: [35, 65],
      backgroundColor: ["#ff6700", "#9A9A9A"],
      borderColor: ["#ff6700", "#9A9A9A"],
      borderWidth: 1,
      fontSize: 12,
    },
  ],
  options: {
    layout: {
      padding: {
        right: 25, //set that fits the best
      },
      fontSize: 12,
    },
  },
};

// var myDoughnutChart = new Chart(donut).Doughnut(donutdata, {
//     tooltipFontSize: 10,
//     percentageInnerCutout : 70
//   });

const StatsCard = ({ title, total, icon, perc, percText, percIcon, small }) => {
  return (
    <Card
      style={{
        height: "87%",
        borderRadius: "12px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        flexDirection: "row",
        justifyContent: "center",
      }}
      className="dashboardStatsCard-mainCard"
    >
      <CardBody>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            lineHeight: "1.5",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <div>
              <CardTitle
                style={{ fontSize: window.innerWidth <= 1444 ? 11 : 14 }}
                tag="h4"
                className="text-uppercase text-muted mb-0"
              >
                {title}
              </CardTitle>
            </div>
            <div>
              <span style={{ fontSize: 30, fontWeight: 700, color: "#333333" }}>
                {total}
              </span>
            </div>
            <div>
              <p style={{ fontSize: window.innerWidth <= 1444 ? 12 : 14 }}>
                <span
                  className="text-success mr-2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <i
                    className={percIcon}
                    style={{ marginTop: "4px", marginRight: "4px" }}
                  />
                  <b>{perc}</b>
                  <span
                    className="text-nowrap"
                    style={{
                      color: "grey",
                      marginLeft: "5px",
                      fontSize: window.innerWidth <= 1444 ? 12 : 14,
                    }}
                  >
                    {percText}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </CardBody>
      {/* <div style={{ marginRight: 20, zIndex: "1000000" }}>
        {icon ? (
          <Pie
            data={data}
            height={"75%"}
            width={"75%"}
            fontSize={"0.4px"}
            style={{ zIndex: 100 }}
          />
        ) : null}
      </div> */}
    </Card>
  );
};

export default StatsCard;
