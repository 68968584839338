/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { FaWindows } from "react-icons/fa";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  // InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import api from "../../services/api";
import { reactLocalStorage } from "reactjs-localstorage";
import { useHistory } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    // getData();
  });
  async function login() {
    const user = {
      email,
      password,
      loading,
    };
    try {
      const userData = await api.login(user);

      let loggedInUserData = userData?.data?.user;

      delete loggedInUserData.password;

      setLoading(true);
      await reactLocalStorage.setObject("userData", loggedInUserData);
      await reactLocalStorage.setObject("token", userData?.data?.token);
      await reactLocalStorage.setObject("sidebar-index", { index: 0 });

      // window.location.href = '/admin/index'
      history.push("/admin/index");
    } catch (e) {
      setError(e.response.data.message);
    }
  }
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted ">
              <h1>Welcome!</h1>
            </div>
            <div className="text-center text-muted mb-4">
              <small>Login to admin panel</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  {/* <InputGroupAddon addonType="prepend"> */}
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  {/* </InputGroupAddon> */}
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </InputGroup>
              </FormGroup>
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <span></span>
                </label>
                <span style={{ marginLeft: "10px", marginTop: "-7px" }}>
                  Remember me
                </span>
              </div>
              <div>
                <Button
                  className="lButton"
                  color="#ff6700"
                  type="button"
                  onClick={() => login()}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {loading === true ? (
                    <Spinner style={{ width: 30, height: 30 }} />
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
