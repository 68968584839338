import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import api from "../../services/api";

const ResetPassword = () => {
  const [state, setState] = useState({
    error: "",
    loading: false,
    newPassword: "",
    confirmPassword: "",
  });

  const mainContent = useRef(null);

  const handleFieldChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetPassword = () => {
    const { newPassword, confirmPassword } = state;

    const hashFragment = window.location.hash;

    // Split the fragment by '/' to get individual parts
    const parts = hashFragment.split("/");

    // Access the values based on their positions
    const email = parts[2];
    const token = parts[3];

    if (isEmpty(newPassword) || isEmpty(confirmPassword)) {
      setState((prev) => ({
        ...prev,
        error: "Please enter both password's first",
      }));

      return;
    }

    if (newPassword.length < 6) {
      setState((prev) => ({
        ...prev,
        error: "Password should contain atleast 6 characters",
      }));
      return;
    }

    if (newPassword !== confirmPassword) {
      setState((prev) => ({
        ...prev,
        error: "New Password and Confirm password does not match",
      }));

      return;
    } else {
      setState((prev) => ({
        ...prev,
        error: "",
        loading: true,
      }));

      let data = {
        newPassword: newPassword,
        token,
      };

      api
        .resetPassword(email, data)
        .then((resp) => {
          console.log({ resp });
          setState((prev) => ({
            ...prev,
            error: "",
            loading: false,
          }));

          toast.success(resp.error || "Passowrd Reset.");

          setTimeout(() => {
            window.close();
          }, 1000);
        })
        .catch((error) => {
          console.log({ error });
          setState((prev) => ({
            ...prev,
            error: "",
            loading: false,
          }));

          console.log("Erorrr:", error?.response?.data?.error);

          return toast.error(
            error?.response?.data?.error ||
              "Some Error Occured. Please try again"
          );
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <div>
      <div className="main-content" ref={mainContent}>
        <img
          alt="..."
          className="resetPasswordLogo"
          src={require("../../assets/img/brand/logo.svg").default}
        />

        <div className="header bg-gradient-reset-password py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6"></Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h1>Reset Password</h1>
                  </div>

                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                        <Input
                          placeholder="New Password"
                          type="password"
                          name="newPassword"
                          onChange={handleFieldChange}
                          value={state.newPassword}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                        <Input
                          placeholder="Confirm Password"
                          type="password"
                          name="confirmPassword"
                          onChange={handleFieldChange}
                          value={state.confirmPassword}
                        />
                      </InputGroup>
                    </FormGroup>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {state.error}
                    </p>

                    <div>
                      <Button
                        className="lButton reset_password_btn"
                        color="#2354d7"
                        type="button"
                        onClick={handleResetPassword}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {state.loading === true ? (
                          <Spinner style={{ width: 30, height: 30 }} />
                        ) : (
                          "Change Password"
                        )}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;
