import React, { Component } from 'react';
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    FormGroup,
    InputGroup,
    Input,
    setOpen1,
    isOpen1,
    // InputGroupAddon,
    InputGroupText,
    CardBody

} from "reactstrap";
import './chat.css'
class SimpleMap extends Component {

    render() {
        return (
            <Card style={{height: '100%', maxHeight: '900px'}}>
                <CardHeader style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                    <h3 >Chat log of <span style={{ fontWeight: 'bold' }}> John Stewart </span> &<span style={{ fontWeight: 'bold' }}> Andrew James</span></h3>
                    <div >
                        <FormGroup className="mb-0">
                            <InputGroup className="input-group-alternative">
                                {/* <InputGroupAddon addonType="prepend"> */}
                                    <InputGroupText>
                                        <i className="fas fa-search" />
                                    </InputGroupText>
                                {/* </InputGroupAddon> */}
                                <Input placeholder="Search chat" type="text" />
                            </InputGroup>
                        </FormGroup>
                    </div>
                </CardHeader>
                <CardBody style={{ backgroundColor: '#F6F9FC', marginTop: '-15px', overflowY: 'auto', height: '500px' }}>
                    <div>
                        {[1, 2, 3, 4, 5, 6].map(index => (
                            index % 2 === 0 ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                    <img
                                        alt="..."
                                        className="rounded-circle"
                                        style={{ width: '30px', height: '30px' }}
                                        src={
                                            require("../../assets/img/theme/team-1-800x800.jpg")
                                                .default
                                        }
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <div className="talk-bubble">
                                            <div className="talktext">
                                                <p>CSS Talk Bubble configured by classNamees. Defaults to square shape, no triangle. Height is auto-adjusting to the height of the text.</p>
                                            </div>
                                        </div>
                                        <h6 style={{ color: '#9A9A9A', marginLeft: '10px' }}> 14:00  04/08/21 </h6>
                                    </div>
                                </div>
                            ) : (

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <div className="talk-bubble-right">
                                            <div className="talktext1">
                                                <p>CSS Talk Bubble configured by classes. Defaults to square shape, no triangle. Height is auto-adjusting to the height of the text.</p>
                                            </div>
                                        </div>
                                        <h6 style={{ color: '#9A9A9A', marginLeft: 'auto', marginRight: '10px' }}> 14:00  04/08/21 </h6>
                                    </div>

                                    <img
                                        alt="..."
                                        className="rounded-circle"
                                        style={{ width: '30px', height: '30px' }}
                                        src={
                                            require("../../assets/img/theme/team-1-800x800.jpg")
                                                .default
                                        }
                                    />
                                </div>
                            )
                        ))}


                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default SimpleMap;