/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Col,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import "./index.css";
import ActivityLog from "components/ActivityLog/ActivityLog";
import api from '../../../services/api'
import { useState, useEffect } from 'react';
import "./index.css"



const Tables = () => {
  const [rowNo, setRowNo] = useState(0);
  const [allAdmins, setAllAdmins] = useState([]);
  const [selectedAdmin, setAdmin] = useState('');
  const [activitylogs, setActivityLogs] = useState([]);


  useEffect(() => {
    getAllAdmins();
  }, []);


  async function getAllAdmins() {
    try {
      const allAdmins = await api.getAllUsers('?userType=Admin');
      if (!allAdmins.length) {
        await setAllAdmins(allAdmins.data);
        getActivityLogs(allAdmins.data[0]._id)
      }
    } catch (e) {
      console.log(e.response)
    }
  }

  async function getActivityLogs(adminId) {
    try {
      const activitylogs = await api.getAllActivitylog(`?adminId=${adminId}`);
      if (!activitylogs.length) {
        await setActivityLogs(activitylogs.data);
      }
    } catch (e) {
      console.log(e.response)
    }
  }

  function convertDate(dateStr) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    const date = new Date(dateStr);
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    
    const formattedDate = `${day.toString().padStart(2, "0")} ${months[monthIndex]}, ${year}`;
    return formattedDate;
  }

  return (
    <>
      <Header />
      <Container className={window.innerWidth <= 1366 ? "mt--7" : "mt--8"} fluid>
        <Row style={{ justifyContent: "space-between" }}>
          <Col xs={12} sm={12} md={12} lg={5} xl={5}>
            <Card className="activtyLogShadow" style={{ height: "100%" }} >
              <CardHeader className="border-0 activeLog-cardHeader">
                <h3 className="mb-0">All Admin</h3>

              </CardHeader>
              <Table style={{ weight: "30%" }} className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ fontSize: '0.625vw', fontWeight: 600 }}>ADMIN NAME</th>
                    <th scope="col" style={{ fontSize: '0.625vw', fontWeight: 600 }}>ROLE</th>
                    <th scope="col" style={{ fontSize: '0.625vw', fontWeight: 600 }}>JOINED</th>

                  </tr>
                </thead>
                <tbody>
                  {allAdmins.map((user, index) => (
                    <tr onClick={() => {
                      setRowNo(index)
                      setAdmin(user._id)
                      getActivityLogs(user._id)
                    }} style={{ cursor: 'pointer' }}
                      className={index === rowNo ? 'arrow_box speech-bubble' : 'arrow_box'}>
                      <td>
                        <Media className="align-items-center">


                          <span style={{ fontSize: '90%', color: '#9A9A9A' }}>
                            {user.name}
                          </span>

                        </Media>
                      </td>
                      <td>
                        <Media className="align-items-center">
                          <Media>
                            <a>
                              <span style={{ fontSize: '90%', color: '#9A9A9A' }}>
                              {user.adminType}
                              </span>
                            </a>
                          </Media>
                        </Media>
                      </td>
                      <td style={{ fontSize: '80%', color: '#9A9A9A' }}>
                      {convertDate(user.createdAt)}
                      </td>



                    </tr>))}
                </tbody>
              </Table>

            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={7} style={{  maxWidth: "58.33333%"}}>
            <Card className="shadow" style={{ width: '100%', marginRight: '40px', }}>
              <ActivityLog activitylogs={activitylogs} />
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default Tables;
