import React, { useState, useEffect, createRef, useRef } from 'react'
import { Link } from "react-router-dom";
import './index.css'
import {
    Card,
    Container,
    Row,
    Modal,
    Button,
    FormGroup,
    Input,

} from "reactstrap";

import { conversationService } from './conversationFBHook';
import ReactLoading from "react-loading";
import './index.css'
export const Conversation = ({ job }) => {
    const { messages } = conversationService();
    const contentIDS = useRef(null)
    // const tuteeId = '62ae51daee65f600180a8f16'
    const tuteeId = job?.tuteeId
    const tutorId = job?.tutorId


    useEffect(() => {
        handleScroll()
    }, [messages])

    const handleScroll = () => {
        contentIDS.current?.scrollIntoView()
    }
    const dateFromTimestamp = (timestamp) => {
        let timestamp1 = new Date(timestamp * 1)
        timestamp1 = timestamp1?.toLocaleTimeString()
        timestamp1 = timestamp1.substr(0, 4) + timestamp1.substr(7, 9)
        let timestamp2 = new Date(timestamp * 1)
        let month1 = timestamp2.getMonth()
        let year1 = timestamp2.getFullYear()
        year1 = String(year1).substr(2, 3)
        timestamp2 = timestamp2?.getDate()
        const month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        month1 = month[month1]
        return (timestamp1 + ' - ' + timestamp2 + ' ' + month1 + ', ' + year1)
    }

    return (
        <div className='conversationMainBox'>
            <div className='chatLog'>
                <div className='conversationHading'>
                    <div className='conversationHadingText1' style={{ margin: "0px 10px 0px 0px" }}>
                        Chat log of
                    </div>
                    <div className='conversationHadingText2'>
                        {job?.tutee[0]?.name}
                    </div>
                    <div className='conversationHadingText1' style={{ margin: "0px 10px" }}>
                        &
                    </div>
                    <div className='conversationHadingText2'>
                        {job?.tutor[0]?.name}
                    </div>
                </div>
                <div className='conversationSearch'>
                    <div
                        className="jobPosting-search"
                    >
                        <div className="form-group has-search" style={{ marginBottom: '0rem' }}>
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" placeholder="Search Chat" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='conversationDivider'>
                <div className='convDiv'></div>
            </div>
            <div className="SuppAllChatMainBox222">
                <div className="SuppChatMsgBox2">
                    {
                        messages?.map((message, i = 0) => {
                            {
                                return (
                                    <div key={message?.timestamp} ref={contentIDS} className='content'>
                                        {
                                            message.idFrom !== tutorId ? (
                                                <div className="SuppChatMsgRecived">
                                                    <div className="SuppChatMsgRecivedRight">
                                                        <div className="SuppChatMsgRecivedMsgBox">
                                                            <p className="SuppChatMsgRecivedMsg">
                                                                {message?.content}
                                                            </p>
                                                        </div>
                                                        <div className="SuppChatMsgRecivedMsgTime">
                                                            <div className="SuppChatMsgRecivedMsgTimeText">
                                                                {dateFromTimestamp(message?.timestamp)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (<div className="SuppChatMsgSent" >
                                                <div className="SuppChatMsgRecived">
                                                    <div className="SuppChatMsgRecivedRight">
                                                        <div className="SuppChatMsgRecivedMsgBox1">
                                                            <p className="SuppChatMsgRecivedMsg1">
                                                                {message?.content}
                                                            </p>
                                                        </div>
                                                        <div className="SuppChatMsgRecivedMsgTime">
                                                            <div className="SuppChatMsgRecivedMsgTimeText1">
                                                                {dateFromTimestamp(message?.timestamp)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                )
                            }
                        }
                        )
                    }
                </div>
            </div>
        </div>
    )
}







