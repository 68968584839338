import Multiselect from "multiselect-react-dropdown";
import React from "react";
import styles from "./styles.module.scss";

const MultiSelectDropdown = ({ title = "Add User", options, onSelect }) => {
  return (
    <div>
      <Multiselect
        className={styles.selectField}
        placeholder={title}
        options={options}
        selectedValues={[]}
        onSelect={onSelect}
        onRemove={onSelect}
        displayValue={"name"}
      />
    </div>
  );
};

export default MultiSelectDropdown;
