import React from "react";
import { Button, Modal, Table } from "reactstrap";

const ViewTutorsBillingModal = ({
  invoiceMonth = "",
  isOpen,
  dataForPdf,
  handleModal,
  handleDownloadPdf,
}) => {
  return (
    <div cl>
      <Modal
        className="modal-dialog-centered tutorBillingModal"
        toggle={(e) => {
          handleModal();
        }}
        isOpen={isOpen}
      >
        <div className="tutorBillingModalContainer" id="divToPrint">
          <div className="downloadPdfHeaderRow">
            <h1>Tutor's Receipt</h1>
            <i
              style={{
                marginLeft: "187px",
                paddingTop: "3px",
                color: "#9A9A9A",
                cursor: "pointer",
              }}
              onClick={(e) => {
                handleModal();
              }}
              className="fas fa-times"
            />
          </div>

          {dataForPdf &&
            Object.entries(dataForPdf).map((tutorInfo) => {
              return (
                <TutorClassesInfo
                  invoiceMonth={invoiceMonth}
                  tutorName={tutorInfo[0]}
                  tutorClassesInfo={tutorInfo[1]}
                />
              );
            })}
        </div>
        <div className="downloadPdfBlock">
          <Button className="btn_after_button" onClick={handleDownloadPdf}>
            Download PDF
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewTutorsBillingModal;

const TutorClassesInfo = ({ tutorName, invoiceMonth, tutorClassesInfo }) => {
  const { tutionClasses, sumOfAllTutionClassesSalary } = tutorClassesInfo;
  return (
    <div>
      <div className="tutorInfoRow">
        Tutor Name: <span className="font-bold">{tutorName}</span>
      </div>
      <div className="tutorInfoRow">
        Bank Details:{" "}
        <span className="font-bold">{tutorClassesInfo.bankInfo}</span>
      </div>

      <div className="tutorInfoRow">
        <Table className="align-items-center table-flush table-Pad" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Tution</th>
              <th scope="col">Invoice # ({invoiceMonth})</th>
              <th scope="col">Tutee</th>
              <th scope="col">Total Bill</th>
              <th scope="col">Tutor Salary</th>
            </tr>
          </thead>

          <tbody>
            {tutionClasses.map((classInfo) => (
              <tr>
                <td>{classInfo.tutionName}</td>
                <td>{classInfo.invoiceNumber}</td>
                <td>{classInfo.tuteeName}</td>
                <td className="text-end">{classInfo.totalBill}</td>
                <td className="text-end">{classInfo.tutorSalary}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="text-end">
        Total Salary:&nbsp;
        <span className="font-bold">{sumOfAllTutionClassesSalary}</span>
      </div>
    </div>
  );
};
