/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import BookIcon from '@mui/icons-material/Book';
// import ReactTags from 'react-tag-autocomplete'
import { COURSES } from "../coursesList";
import api from "../../../services/api";
import ReactLoading from "react-loading";
import { reactLocalStorage } from "reactjs-localstorage";
import "./index.css"

const suggestions = COURSES.map((country) => {
  return {
    id: country,
    name: country,
  };
});

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Courses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      tab: 0,
      tags: [
        { id: "Thailand", name: "Thailand" },
        { id: "India", name: "India" },
      ],
      suggestions: suggestions,
      colors: [
        {
          hex: "#FF6363",
        },
        {
          hex: "#F7941D",
        },
        {
          hex: "#A71930",
        },
        {
          hex: "#679146",
        },
        {
          hex: "#00759A",
        },
        {
          hex: "#F7941D",
        },
        {
          hex: "#A71930",
        },
        {
          hex: "#679146",
        },
      ],
      courseColor: "",
      pageNo: 1,
      subjectName: "",
      allprimary: false,
      primary1: false,
      primary2: false,
      primary3: false,
      primary4: false,
      primary5: false,
      primary6: false,
      allsecondary: false,
      secondary1: false,
      secondary2: false,
      secondary3: false,
      secondary4: false,
      secondary5: false,
      alljuniorCollageH: false,
      juniorCollageH1: false,
      juniorCollageH2: false,
      juniorCollageH3: false,
      months1: false,
      months2: false,
      allCourses: [],
      isLoading: false,
      errMsg: "",
      logginUser: {},
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }

  getCurrentDateTime() {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();
  
    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
  
    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async addCourseLog(name, msg) {
    const data = {
      adminId: this.state.logginUser._id,
      message: name,
      message1: msg,
      activityType: "Add New Course",
      dateTime: getCurrentDateTime()
    };
    await api.postActivitylog(data);
    this.setState({ isOpen: false });
    this.props.closeCalendar();
  }

  async GetAllCourses() {
    try {
      this.setState({ isLoading: true });
      const getAllCourses = await api.getAllCourses("");
      if (!getAllCourses.length) {
        await this.setState({ allCourses: getAllCourses.data });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e.response, "e.res");
    }
  }
  async addCourse() {
    try {
      this.setState({ isLoading: true });
      var courseLevel = [];
      var courseLevelLog = "";
      if (this.state.primary1) {
        courseLevel.push("Primary 1");
        courseLevelLog += "Primary 1";
      }
      if (this.state.primary2) {
        courseLevel.push("Primary 2");
        courseLevelLog += "Primary 2";
      }
      if (this.state.primary3) {
        courseLevel.push("Primary 3");
        courseLevelLog += "Primary 3";
      }
      if (this.state.primary4) {
        courseLevel.push("Primary 4");
        courseLevelLog += "Primary 4";
      }
      if (this.state.primary5) {
        courseLevel.push("Primary 5");
        courseLevelLog += "Primary 5";
      }
      if (this.state.primary6) {
        courseLevel.push("Primary 6");
        courseLevelLog += "Primary 6";
      }
      if (this.state.secondary1) {
        courseLevel.push("Secondary 1");
        courseLevelLog += "Secondary 1";
      }
      if (this.state.secondary2) {
        courseLevel.push("Secondary 2");
        courseLevelLog += "Secondary 2";
      }
      if (this.state.secondary3) {
        courseLevel.push("Secondary 3");
        courseLevelLog += "Secondary 3";
      }
      if (this.state.secondary4) {
        courseLevel.push("Secondary 4");
        courseLevelLog += "Secondary 4";
      }
      if (this.state.secondary5) {
        courseLevel.push("Secondary 5");
        courseLevelLog += "Secondary 5";
      }
      if (this.state.juniorCollageH1) {
        courseLevel.push("Junior College H1");
        courseLevelLog += "Junior College H1";
      }
      if (this.state.juniorCollageH2) {
        courseLevel.push("Junior College H2");
        courseLevelLog += "Junior College H2";
      }
      if (this.state.juniorCollageH3) {
        courseLevel.push("Junior College H3");
        courseLevelLog += "Junior College H3";
      }

      var courseDuration = [];
      if (this.state.months1) {
        courseDuration.push("3 Months");
      }
      if (this.state.months2) {
        courseDuration.push("6 Months");
      }

      if (this.state.subjectName) {
        if (this.state.courseColor) {
          if (courseLevel.length) {
            if (courseDuration.length) {
              const data = {
                courseName: this.state.subjectName,
                courseDuration: courseDuration,
                courseLevel: courseLevel,
                courseColor: this.state.courseColor,
              };
              const getAllCourses = await api.addCourse(data);
              this.GetAllCourses();
              this.addCourseLog(this.state.subjectName, courseLevelLog);
              this.setState({
                isOpen: false,
                secondary1: false,
                secondary2: false,
                allprimary: false,
                primary1: false,
                primary2: false,
                primary3: false,
                primary4: false,
                primary5: false,
                primary6: false,
                allsecondary: false,
                secondary1: false,
                secondary2: false,
                secondary3: false,
                secondary4: false,
                secondary5: false,
                alljuniorCollageH: false,
                juniorCollageH1: false,
                juniorCollageH2: false,
                juniorCollageH3: false,
                months1: false,
                months2: false,
              });
              this.setState({ isLoading: false });
            } else {
              this.setState({ errMsg: "Please Enter Course Duration" });
            }
          } else {
            this.setState({ errMsg: "Please Enter Course Level" });
          }
        } else {
          this.setState({ errMsg: "Please Select Color" });
        }
      } else {
        this.setState({ errMsg: "Please Enter Subject name" });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e.response, "e.res222");
    }
  }
  async componentDidMount() {
    const user = await reactLocalStorage.getObject("userData");
    this.setState({ logginUser: user });
    this.GetAllCourses();
  }
  render() {
    return (
      <>
        <Header />
        <hr
          style={{
            // height: "1px",
            borderWidth: 0,
            color: "rgba(255, 255, 255, 0.2)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            width: "90%",
          }}
        />
        <Container className={window.innerWidth <= 1366 ? "mt--8 courses-cont" : "mt--9 courses-cont"} fluid>
          {/* Table */}
          <Row className="courses-row">
            <div className="col courses-col">
              <Card className="shadow courses-shadow">
                <CardHeader
                  className="border-0"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minHeight: "80px",
                  }}
                >
                  <h3 className="mb-0">Showing all Courses</h3>

                  {/* <Button
                    style={{
                      backgroundColor: "#FF6700",
                      border: "1px solid #FF6700",
                      color: "#fff",
                    }}
                    type="button"
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    Add New{" "}
                  </Button> */}
                </CardHeader>
                <div className="courses-tablePag">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        >
                          SUBJECT TITLE
                        </th>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        >
                          LEVEL
                        </th>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        >
                          DURATION
                        </th>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        ></th>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        ></th>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        ></th>
                        <th
                          scope="col"
                          style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.isLoading ? (
                        <tr
                          style={{
                            width: "100%",
                            height: "100%",
                            marginLeft: "10rem",
                          }}
                        >
                          <td colSpan={9}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ReactLoading
                                type={"spin"}
                                color={"#ff6700"}
                                height={30}
                                width={30}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        this.state.allCourses.map((course) => (
                          <tr>
                            <th scope="row">
                              <Media className="align-items-center" >
                                {
                                  <BookIcon sx={{ fontSize: "36px", color: course.courseColor, width: "30px", height: "30px" }} />
                                }
                                <Media>
                                  <div
                                    style={{ fontSize: "14px", marginLeft: "13px", color: "#9A9A9A", fontWeight: "600" }}
                                  >
                                    {course.courseName}
                                  </div>
                                </Media>
                              </Media>
                            </th>
                            <td style={{ color: "#9A9A9A", fontSize: "90%", display: "flex", flexDirection: "column", height: "110px" }} className="text-break">
                              <div className="text-break" style={{ fontSize: '14px', height: "auto", width: "313px" }}>
                                <p style={{ marginBottom: "0rem", display: "flex", flexDirection: "row" }}>
                                  {course.courseLevel.map((cl, ind) => {

                                    if (cl === "Primary 1" || cl === "Primary 2" || cl === "Primary 3" || cl === "Primary 4" || cl === "Primary 5" || cl === "Primary 6") {
                                      return cl + ",  ";
                                    }
                                  })}
                                </p>
                                <p style={{ marginBottom: "0rem" }}>
                                  {course.courseLevel.map((cl, ind) => {

                                    if (cl === "Secondary 1" || cl === "Secondary 2" || cl === "Secondary 3" || cl === "Secondary 4" || cl === "Secondary 5") {
                                      return cl + ",  ";
                                    }
                                  })}
                                </p>
                                <p style={{ marginBottom: "0rem" }}>
                                  {course.courseLevel.map((cl, ind) => {
                                    if (cl === "Junior College H1" || cl === "Junior College H2" || cl === "Junior College H3") {
                                      return cl + ",  ";
                                    }
                                  })}
                                </p>

                              </div>
                            </td>
                            <td style={{ color: "#9A9A9A", fontSize: "90%" }}>
                              {course.courseDuration.map((cd, ind) => {
                                if (ind === course.courseDuration.length - 1) {
                                  return cd + "  ";
                                } else {
                                  return cd + ",  ";
                                }
                              })}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>

                            {/* <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td> */}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  {/* <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem
                          className={this.state.pageNo === 1 ? "disabled" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={() => {
                              if (this.state.pageNo > 1) {
                                this.setState({ pageNo: this.state.pageNo - 1 });
                              }
                            }}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem
                          className={this.state.pageNo === 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => this.setState({ pageNo: 1 })}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem
                          className={this.state.pageNo === 2 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => this.setState({ pageNo: 2 })}
                          >
                            2 <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem
                          className={this.state.pageNo === 3 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => this.setState({ pageNo: 3 })}
                          >
                            3
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={() => {
                              this.setState({ pageNo: this.state.pageNo + 1 });
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter> */}
                </div>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered modal-mainBox"
          isOpen={this.state.isOpen}
          toggle={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <div className="Modal-Course_Edit-Mian">
            <div style={{ marginTop: "40px", textAlign: "center", marginBottom: "8px" }}>
              <h1 style={{ fontSize: "28px", color: "#333333", fontWeight: 600 }}>Adding new course</h1>
              <p style={{ color: "#9A9A9A", fontSize: "14px", fontWeight: 400 }}>
                {" "}
                Enter below information to add a new course
              </p>
            </div>
            <div className="Modal-Course_Edit-sub-Color">
              <div className="Modal-Course_Edit-sub">
                <div className="Modal-Select-Color">
                  Subject name
                </div>
                <div
                  style={{
                    width: "247px",
                    height: "46px",
                  }}
                >
                  <FormGroup style={{ width: "100%", }} >
                    <Input
                      onChange={(e) => this.setState({ subjectName: e.target.value })}
                      type="text"
                      style={{
                        border: "0px", boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15)",
                        borderRadius: "6px"
                      }}
                      placeholder="Choose subject"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="Modal-Course_Edit-Color">
                <div className="Modal-Select-Color">
                  Select color
                </div>
                <div>
                  <input
                    type="color"
                    list="presetColors"
                    style={{
                      border: "0px", boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15)",
                      borderRadius: "6px", width: "263px", height: "46px", border: "0px none #FFFFFF", backgroundColor: "#FFFFFF", color: "#FFFFFF"
                    }}
                    onChange={(e) => this.setState({ courseColor: e.target.value })}
                  />
                  <datalist id="presetColors">
                    {this.state.colors.map((color) => (
                      <option style={{ height: "50px" }}>{color.hex}</option>
                    ))}
                  </datalist>
                </div>
              </div>
            </div>
            <div style={{ margin: "10px 40px" }}>
              <div style={{ margin: "30px 2px" }}>
                <div className="Modal-Select-Color1">
                  Select Duration
                </div>

                <div className="Duration_check-Box">
                  <div className="Duration_check-Box-1">
                    <label className="custom-checkbox">
                      <input
                        onChange={(e) =>
                          this.setState({ months1: !this.state.months1 })
                        }
                        type="checkbox"
                      />
                      <span></span>
                    </label>
                    <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                      3 Months
                    </span>
                  </div>
                  <div className="Duration_check-Box-2">
                    <label className="custom-checkbox">
                      <input
                        onChange={(e) =>
                          this.setState({ months2: !this.state.months2 })
                        }
                        type="checkbox"
                      />
                      <span></span>
                    </label>
                    <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                      6 Months
                    </span>
                  </div>
                </div>
              </div>



              <div style={{ margin: "15px 2px" }}>
                <div style={{ margin: "30px 2px" }}>
                  <div className="Modal-Select-Color1">
                    Select Levels
                  </div>

                  <div className="Level_check-Box">
                    <div className="Level_check-Box-Primary">

                      <div className="Level_check-Box-2">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                allprimary: !this.state.allprimary,
                                primary1: !this.state.allprimary,
                                primary2: !this.state.allprimary,
                                primary3: !this.state.allprimary,
                                primary4: !this.state.allprimary,
                                primary5: !this.state.allprimary,
                                primary6: !this.state.allprimary,
                              })
                            }
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          All Primary
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                primary1: !this.state.primary1,
                              })
                            }
                            checked={this.state.primary1}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Primary 1
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                primary2: !this.state.primary2,
                              })
                            }
                            type="checkbox"
                            checked={this.state.primary2}
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Primary 2
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                primary3: !this.state.primary3,
                              })
                            }
                            checked={this.state.primary3}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Primary 3
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                primary4: !this.state.primary4,
                              })
                            }
                            checked={this.state.primary4}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Primary 4
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                primary5: !this.state.primary5,
                              })
                            }
                            checked={this.state.primary5}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Primary 5
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                primary6: !this.state.primary6,
                              })
                            }
                            checked={this.state.primary6}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Primary 6
                        </span>
                      </div>
                    </div>
                    <div className="Level_check-Box-Secondary">

                      <div className="Level_check-Box-2">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                allsecondary: !this.state.allsecondary,
                                secondary1: !this.state.allsecondary,
                                secondary2: !this.state.allsecondary,
                                secondary3: !this.state.allsecondary,
                                secondary4: !this.state.allsecondary,
                                secondary5: !this.state.allsecondary,
                              })
                            }
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          All Secondary
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                secondary1: !this.state.secondary1,
                              })
                            }
                            checked={this.state.secondary1}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Secondary 1
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                secondary2: !this.state.secondary2,
                              })
                            }
                            type="checkbox"
                            checked={this.state.secondary2}
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Secondary 2
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                secondary3: !this.state.secondary3,
                              })
                            }
                            checked={this.state.secondary3}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Secondary 3
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                secondary4: !this.state.secondary4,
                              })
                            }
                            checked={this.state.secondary4}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Secondary 4
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                secondary5: !this.state.secondary5,
                              })
                            }
                            checked={this.state.secondary5}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Secondary 5
                        </span>
                      </div>
                    </div>
                    <div className="Level_check-Box-Collage">

                      <div className="Level_check-Box-2">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                alljuniorCollageH: !this.state.alljuniorCollageH,
                                juniorCollageH1: !this.state.alljuniorCollageH,
                                juniorCollageH2: !this.state.alljuniorCollageH,
                                juniorCollageH3: !this.state.alljuniorCollageH,
                              })
                            }
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          All Junior College
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                juniorCollageH1: !this.state.juniorCollageH1,
                              })
                            }
                            checked={this.state.juniorCollageH1}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Junior College H1
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                juniorCollageH2: !this.state.juniorCollageH2,
                              })
                            }
                            type="checkbox"
                            checked={this.state.juniorCollageH2}
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Junior College H2
                        </span>
                      </div>
                      <div className="Level_check-Box-1">
                        <label className="custom-checkbox">
                          <input
                            onChange={(e) =>
                              this.setState({
                                juniorCollageH3: !this.state.juniorCollageH3,
                              })
                            }
                            checked={this.state.juniorCollageH3}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                        <span style={{ marginLeft: "8px", marginBottom: "7px", fontSize: "14px", color: "#9A9A9A" }}>
                          Junior College H3
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {this.state.errMsg}
            </p>
            <div className="modal-footer">
              <Button
                style={{
                  backgroundColor: "#FF6700",
                  border: "1px solid #FF6700",
                  color: "#fff",
                  width: "100%",
                }}
                type="button"
                onClick={() => this.addCourse()}
              >
                {this.state.isLoading ?
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                  : "Add Course"}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Courses;
