import React, { useState, useEffect } from "react";
import { MsgBox } from "./msgBox";
import MessageServiceProvider from "./messageBoxFBHook";
import { adminService } from "./inBoxFBHook";
import Avatar from "@mui/material/Avatar";

export const AllChat = ({ loggedUser, closeModal }) => {
  const [openModal, setOpenModal] = useState(false);
  const [doc, setDoc] = useState({});
  const { data, isLoading } = adminService();
  // const [adminLabelCheck, setAdminLabelCheck] = useState(false)

  // console.log(isLoading)
  function handleAdminLabelCheck(chatAdmin) {
    if (chatAdmin == loggedUser._id) {
      return true;
    } else {
      return false;
    }
  }

  console.log("All CHat", { data });

  return (
    <>
      {openModal === false ? (
        data?.map((doc, i = 0) => {
          return (
            <div key={doc?.id}>
              <div className="SuppDivider">
                <div className="divider"></div>
              </div>
              <div className="PrimaryBox">
                <div className="supportChatMyInboxDivider">
                  <div
                    className="supportChatMyInbox"
                    onClick={() => {
                      setOpenModal(true), setDoc(doc);
                    }}
                  >
                    <div className="LeftSuppAllChat">
                      <div className="ProfilePicSuppMyInbox">
                        <Avatar
                          className="ProfilePicSuppMyInbox1"
                          alt={doc?.adminNameList[0]}
                          src="/"
                        />
                      </div>
                      <div className="AgentAndTitleBoxSupp">
                        <div className="ChatSenderSuppMyInbox">
                          {doc?.adminNameList[0]}
                        </div>
                        {handleAdminLabelCheck(doc?.adminList[0]) == true ? (
                          <div className="MeLabelCoverBox">
                            <div className="MeLabelSuppChat">Me</div>
                          </div>
                        ) : (
                          <div className="SuperAdminLabelCoverBox">
                            <div className="SuperAdminLabelSuppChat">Admin</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="RightSuppAllChat">
                      <div className="AgentAndTitleBoxSupp2">
                        <div className="ChatSenderSuppMyInbox2">
                          {doc?.appUserName}
                        </div>
                        {doc?.appUserType === "Tutee" ? (
                          <div className="TuteeLabelCoverBox support-userType">
                            <div className="TuteeLabelCover">TUTEE</div>
                          </div>
                        ) : (
                          <div className="TUTORLabelSuppChatBox support-userType">
                            <div className="TUTORLabelSuppChat">TUTOR</div>
                          </div>
                        )}
                      </div>
                      <div className="ProfilePicSuppMyInbox">
                        <Avatar
                          className="ProfilePicSuppMyInbox1"
                          alt={doc?.appUserName}
                          src={doc?.appUserPhotoUrl}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SuppDivider">
                  <div className="divider"></div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <MessageServiceProvider>
          <MsgBox
            chatType="allChat"
            loggedUser={loggedUser}
            chatInfo={doc}
            chatId={doc.id}
            closeModal={() => setOpenModal(false)}
          />
          {/* <MsgBox chatType="myChat" loggedUser={loggedUser} chatInfo={doc} chatId={docId} closeModal={() => setOpenModal(false)} /> */}
        </MessageServiceProvider>
      )}
    </>
  );
};
