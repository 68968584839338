import { Grid } from "@mui/material";
import Dropdown from "components/Dropdown";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import React from "react";
import { Button, Input, Modal } from "reactstrap";

const CreateVoucherModal = ({
  err,
  isOpen = false,
  setOpen,
  setPrice,
  publishTo,
  setDate,
  allTutees,
  onSelect,
  postVoucher,
  selectedTutee,
  setVoucherName,
  handleTuteeChange,
  handleSelectChange,
}) => {
  const setMinDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    return formattedDate;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => setOpen(!isOpen)}
    >
      <div style={{ padding: "25px" }}>
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>Create Voucher</h1>
          <p style={{ color: "#9A9A9A" }}> Create and publish a new voucher</p>
        </div>
        <div className="voucher-modal-data">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                onChange={(e) => setVoucherName(e.target.value)}
                type="text"
                placeholder="Enter voucher name"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                placeholder="Price"
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                onChange={(e) => setDate(e.target.value)}
                type="date"
                min={setMinDate()}
              />
            </Grid>
          </Grid>
        </div>

        <div>
          <h5>Select Tutee</h5>
        </div>
        <div>
          <Dropdown
            name="publish"
            value={publishTo}
            options={[
              { label: "Is Global", value: "Is Global" },
              { label: "Choose tutee", value: "Choose tutee" },
            ]}
            onChange={handleSelectChange}
          />
        </div>
        {publishTo === "Is Global" && (
          <div className="voucher-choose-tutee">
            <MultiSelectDropdown
              title="Add User"
              options={allTutees}
              onSelect={onSelect}
            />
          </div>
        )}
        {publishTo === "Choose tutee" && (
          <div className="voucher-choose-tutee">
            <Dropdown
              name="Tutee"
              value={selectedTutee}
              options={allTutees.map((item) => {
                return {
                  label: item.name,
                  value: item.name,
                };
              })}
              onChange={handleTuteeChange}
            />
          </div>
        )}

        {err && (
          <p style={{ textAlign: "center", color: "red", marginTop: "20px" }}>
            {err}
          </p>
        )}
        <div className="modal-footer">
          <Button
            style={{ width: "100%", height: "40px", paddingTop: "8px" }}
            className="modalFooter publishVoucher"
            onClick={() => postVoucher()}
          >
            Publish Voucher
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateVoucherModal;
