/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  FormGroup,
  InputGroup,
  Container,
  Input,
  Modal,
  Row,
  CardBody,
  Badge,
} from "reactstrap";
import { useState, useEffect } from "react";
// core components
import Header from "components/Headers/Header.js";
import "./index.css";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import ReactLoading from "react-loading";
import ReactStars from "react-rating-stars-component";
import { reactLocalStorage } from "reactjs-localstorage";
import ActivityLog from "components/ActivityLog/ActivityLog";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import Avatar from "@mui/material/Avatar";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import e from "cors";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import ReviewListModal from "components/ReviewListModal";
import { toast } from "react-toastify";
import { getCurrentUserInfo } from "helpers/common-helper";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${"#FF6700"};
  cursor: pointer;
  font-size: 16px;
  height: 34px;
  font-weight: bold;
  // background-color: transparent;
  background-color: ${"#FFF8F1"};
  width: 100%;
  margin: 0.313vw 0.5vw;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${"#FFE7CE"};
  }

  &:focus {
    color: #ffffff;
    border-radius: 6px;
    outline: 2px solid ${"#333333"};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${"#FF6700"};
    color: ${"#FFFFFF"};
    margin: 0.313vw 0.5vw;
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 20.042vw;
  background-color: ${"#FFF8F1"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const Tables = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminType, setAdminType] = useState("");
  const [superAdmin, setSuperAdmin] = useState(true);
  const [analyst, setAnalyst] = useState(false);
  const [worker, setWorker] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [allusers, setAllUsers] = useState([]);
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedUser, setUser] = useState(false);
  const [activitylogs, setActivityLogs] = useState([]);
  const [showElement, setShowElement] = useState(true);

  const [state, setState] = useState({
    selectedTutoreIndex: 0,
    reviewModalIsOpen: false,
    selectedUser: {},
    isEditMode: false,
    editUserInfo: {},
  });

  useEffect(() => {
    setShowElement(true);
    setTimeout(function () {
      setShowElement(false);
    }, 1500);
  }, [tab]);

  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setUser(user);
    setIsLoading(true);
    await getAllUsers(0);
    setIsLoading(false);
  }, []);

  async function getActivityLogs(adminId) {
    try {
      const activitylogs = await api.getAllActivitylog(`?adminId=${adminId}`);

      if (!activitylogs.length) {
        await setActivityLogs(activitylogs.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  }
  async function getAllUsers(tab) {
    try {
      let userType = "";
      if (tab === 0) {
        userType = "Tutor";
      } else if (tab === 1) {
        userType = "Tutee";
      } else if (tab === 2) {
        userType = "Admin";
      }
      const allUsers = await api.getAllUsers(
        `?userType=${userType}&jobDetail=true&reviewDetail=true`
      );
      // if (!allusers.length) {
      setAllUsers(allUsers.data);
      // }
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  async function editUser(id, data) {
    try {
      const user = await api.editUser(id, data);
      await setUser(user.data);
      getAllUsers(tab);
    } catch (e) {
      console.log(e.response);
    }
  }

  async function viewProfile(user, type) {
    const data = {
      adminId: loggedUser._id,
      userId: user._id,
      activityType: "View Profile",
      message: type,
    };
  }
  const size = useWindowSize();

  const isSuperAdmin = getCurrentUserInfo()?.adminType === "SuperAdmin";

  const handleAddAdmin = async () => {
    const { editUserInfo } = state;
    try {
      let payload;

      if (state.isEditMode) {
        payload = {
          user: {
            name: editUserInfo.name,
            ...state.editUserInfo,
          },
        };
      } else {
        payload = {
          name,
          status: "Active",
          email,
          password,
          adminType,
          userType: "Admin",
          isMobileActive: false,
        };
      }

      let apiToCall = state.isEditMode ? api.editUserByAdmin : api.postNewUser;

      apiToCall(payload, state?.selectedUser?._id)
        .then((result) => {
          if (result.status) {
            setOpen(false);
            getAllUsers(2);
          } else {
            setOpen(false);
          }
        })
        .catch((error) => {
          console.log({ error });
          return toast.error("Some Error Occured.");
        });
      console.log("result");
    } catch (e) {
      console.log(e.response);
    }
  };

  const handleReviewModal = () => {
    setState((prev) => ({
      ...prev,
      reviewModalIsOpen: !prev.reviewModalIsOpen,
    }));
  };

  const handleReviewAction = (tutorIndex = 0) => {
    setState((prev) => ({
      ...prev,
      selectedTutoreIndex: tutorIndex,
      reviewModalIsOpen: !prev.reviewModalIsOpen,
    }));
  };

  console.log({ worker, analyst, state, superAdmin, adminType });

  // const size = useWindowSize();
  return (
    <>
      <Header />
      <hr
        style={{
          // height: "1px",
          borderWidth: 0,
          color: "rgba(255, 255, 255, 0.2)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          width: "90%",
        }}
      />
      <Container
        className={
          window.innerWidth <= 1366 ? "mt--8 user-cont" : "mt--9 user-cont"
        }
        fluid
      >
        <Row className="user-row">
          <div
            className="col user-col"
            style={{ height: "97%", width: "100%" }}
          >
            <Card className="shadow user-shadow">
              <CardHeader
                className="border-0"
                style={
                  size.width < 850
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "83px",
                        maxHeight: "83px",
                        padding: "1.042vw 1.250vw",
                        margin: "0px",
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "83px",
                        maxHeight: "83px",
                        padding: "1.042vw 1.250vw",
                        margin: "0px",
                      }
                }
              >
                {/* <h3 className="mb-0">
                  {tab === 0
                    ? "Showing all tutors"
                    : tab === 1
                    ? "Showing all tutees"
                    : "Showing all admins"}
                </h3> */}
                <h3
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                  className="mb-0"
                >
                  Users
                </h3>
                {/* <input placeholder="Search"/> */}
                {tab === 2 ? (
                  <div style={{ minWidth: 182 }}>
                    {isSuperAdmin && (
                      <button
                        style={{
                          width: "124px",
                          height: "34px",
                          fontSize: "16px",
                          backgroundColor: "#FF6700",
                          border: "1px solid #FF6700",
                          color: "#fff",
                          float: "right",
                          boxShadow:
                            "0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1)",
                          borderRadius: "6px",
                        }}
                        type="button"
                        onClick={() => setOpen(true)}
                      >
                        Add Admin{" "}
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    onChange={(e) => setSearchText(e.target.value)}
                    className={size.width > 840 ? "jobPosting-search" : "main2"}
                  >
                    {/* <div style={{ marginBottom: "0px" }} className="form-group has-search search-control">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search User"
                      />
                    </div> */}
                    <TextField
                      id="search"
                      type="search"
                      label="Search User"
                      // value={searchTerm}
                      // onChange={handleChange}
                      // sx={{ width: 160 }}
                      sx={{
                        width: { sm: 160 },
                        "& .MuiInputBase-root": {
                          height: 50,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              </CardHeader>

              <TabsUnstyled defaultValue={0} className="unstyledTab">
                <TabsList className="tablist">
                  <Tab
                    onClick={async () => {
                      await setTab(0);
                      await getAllUsers(0);
                    }}
                  >
                    Tutor
                  </Tab>
                  <Tab
                    onClick={async () => {
                      await setTab(1);
                      await getAllUsers(1);
                    }}
                  >
                    Tutee
                  </Tab>
                  <Tab
                    onClick={async () => {
                      await setTab(2);
                      await getAllUsers(2);
                    }}
                  >
                    Admin
                  </Tab>
                </TabsList>
                {tab === 0 && (
                  <TabPanel value={0}>
                    <div>
                      {showElement ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "30px",
                          }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "650px", overflowY: "scroll" }}>
                          <Table
                            className="align-items-center table-flush"
                            style={{ overflowY: "scroll", marginTop: "41px" }}
                            responsive
                          >
                            <thead className="thead-light sticky">
                              <tr
                                style={{
                                  width: "100%",
                                }}
                              >
                                <th scope="col" className="UTTH1">
                                  FULL NAME
                                </th>
                                <th scope="col" className="UTTH1">
                                  EMAIL
                                </th>
                                <th scope="col" className="UTTH1">
                                  PHONE
                                </th>
                                <th scope="col" className="UTTH1">
                                  ONGOING JOBS
                                </th>
                                <th scope="col" className="UTTH1">
                                  TOTAL JOBS
                                </th>
                                <th scope="col" className="UTTH1">
                                  RATING
                                </th>
                                <th scope="col" className="UTTH1">
                                  REVIEWS
                                </th>
                                {/* <th scope="col" className="UTTH1">
                                  EARNING
                                </th> */}
                                <th scope="col" className="UTTH1">
                                  STATUS
                                </th>
                                <th scope="col" className="UTTH1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    marginLeft: "10rem",
                                  }}
                                >
                                  <td colSpan={9}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ff6700"}
                                        height={30}
                                        width={30}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                allusers
                                  .filter((val) => {
                                    if (searchText === "") {
                                      return val;
                                    } else if (
                                      val.name
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    ) {
                                      return val;
                                    } else if (
                                      val.email
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    ) {
                                      return val;
                                    } else if (val.mobilePhone) {
                                      return val;
                                    }
                                  })
                                  .map((user, index) => (
                                    <tr className="UTR">
                                      <td
                                        scope="row"
                                        className="users-tableData1"
                                      >
                                        <Media className="align-items-center">
                                          {user.photoUrl ? (
                                            <img
                                              className="rounded-circle mr-3"
                                              style={{
                                                width: "1.979vw",
                                                height: "1.979vw",
                                              }}
                                              src={user.photoUrl}
                                            />
                                          ) : (
                                            <Avatar
                                              className="mr-3"
                                              style={{
                                                width: "1.979vw",
                                                height: "1.979vw",
                                                fontSize: "1.042vw",
                                                backgroundColor: "#CED4DA",
                                              }}
                                              alt={user.name}
                                              src="/"
                                            />
                                          )}

                                          <Media>
                                            <Link
                                              style={{
                                                fontSize: "0.729vw",
                                                fontWeight: 600,
                                              }}
                                              to={`/admin/Tutor/${user._id}`}
                                              onClick={() =>
                                                viewProfile(user, "tutor")
                                              }
                                            >
                                              {user.name}
                                            </Link>
                                          </Media>
                                        </Media>
                                      </td>
                                      <td
                                        style={{ fontSize: "0.729vw" }}
                                        className="users-tableData2"
                                      >
                                        {" "}
                                        {user.email}{" "}
                                      </td>
                                      <td
                                        style={{ fontSize: "0.729vw" }}
                                        className="users-tableData3"
                                      >
                                        {user.mobilePhone || "---"}
                                      </td>
                                      <td
                                        style={{ fontSize: "0.729vw" }}
                                        className="users-tableData4"
                                      >
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} */}
                                        {user.jobDetails.ongoing}
                                      </td>
                                      <td
                                        style={{ fontSize: "0.729vw" }}
                                        className="users-tableData5"
                                      >
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} */}
                                        {user.jobDetails.total}
                                      </td>
                                      <td
                                        className="users-tableData6 cursor-pointer"
                                        onClick={() =>
                                          handleReviewAction(index)
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "#454545",
                                              fontSize: "0.729vw",
                                            }}
                                          >
                                            <i
                                              className="fa fa-star"
                                              style={{ color: "#ffd700" }}
                                            ></i>
                                            &nbsp;
                                            {(
                                              user?.reviewDetails?.rating ?? "0.0"
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style={{ fontSize: "0.729vw" }}
                                        className="users-tableData7 cursor-pointer"
                                        onClick={() =>
                                          handleReviewAction(index)
                                        }
                                      >
                                        {/* &nbsp;&nbsp;&nbsp;{" "} */}
                                        {user?.reviewDetails?.totalReviews || 0}
                                      </td>
                                      {/* <td
                                        style={{ fontSize: "0.729vw" }}
                                        className="users-tableData8"
                                      >
                                        200
                                      </td> */}
                                      <td
                                        style={{
                                          color:
                                            user.status === "Active"
                                              ? "#06BE02"
                                              : "red",
                                          fontSize: "0.729vw",
                                        }}
                                        className="users-tableData9"
                                      >
                                        {user.status === "Active" ? (
                                          <span>
                                            <i
                                              className="far fa-check-circle"
                                              style={{ fontSize: "0.729vw" }}
                                            ></i>{" "}
                                            Active{" "}
                                          </span>
                                        ) : (
                                          <span>
                                            <i
                                              className="fas fa-ban"
                                              style={{ fontSize: "0.729vw" }}
                                            ></i>
                                            &nbsp;Blocked
                                          </span>
                                        )}
                                      </td>
                                      <td
                                        style={{ fontSize: "70%" }}
                                        className="text-right users-tableData10"
                                      >
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            className="btn-icon-only text-light"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="fas fa-ellipsis-v" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="dropdown-menu-arrow"
                                            right
                                          >
                                            <Link
                                              to={`/admin/Tutor/${user._id}`}
                                              onClick={() =>
                                                viewProfile(user, "tutor")
                                              }
                                            >
                                              <DropdownItem>
                                                View Profile
                                              </DropdownItem>
                                            </Link>

                                            {getCurrentUserInfo()?.adminType ===
                                              "Admin" && (
                                              <DropdownItem
                                                onClick={(e) =>
                                                  editUser(user._id, {
                                                    user: {
                                                      status:
                                                        user?.status ===
                                                        "Active"
                                                          ? "Blocked"
                                                          : "Active",
                                                    },
                                                  })
                                                }
                                              >
                                                {user?.status === "Active"
                                                  ? "Block"
                                                  : "Unblock"}
                                              </DropdownItem>
                                            )}
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  ))
                              )}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                )}
                {tab === 1 && (
                  <TabPanel value={1}>
                    <div>
                      {showElement ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "30px",
                          }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "650px", overflowY: "scroll" }}>
                          <Table
                            className="align-items-center table-flush"
                            style={{ marginTop: "41px" }}
                            responsive
                          >
                            <thead
                              className="thead-light sticky-top"
                              style={{
                                width: "100%",
                              }}
                            >
                              <tr>
                                <th scope="col" className="UTTTH1">
                                  FULL NAME
                                </th>
                                <th scope="col" className="UTTTH1">
                                  EMAIL
                                </th>
                                <th scope="col" className="UTTTH1">
                                  PHONE
                                </th>
                                <th scope="col" className="UTTTH1">
                                  ONGOING JOBS
                                </th>
                                <th scope="col" className="UTTTH1">
                                  TOTAL JOBS
                                </th>
                                <th scope="col" className="UTTTH1">
                                  STATUS
                                </th>

                                <th scope="col" className="UTTTH1" />
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    marginLeft: "10rem",
                                  }}
                                >
                                  <td colSpan={9}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ff6700"}
                                        height={30}
                                        width={30}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                allusers
                                  .filter((val) => {
                                    if (searchText === "") {
                                      return val;
                                    } else if (
                                      val.name
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    ) {
                                      return val;
                                    } else if (
                                      val.email
                                        .toLowerCase()
                                        .includes(searchText.toLowerCase())
                                    ) {
                                      return val;
                                    }
                                  })
                                  .map((user) => (
                                    <tr className="UTR">
                                      <th scope="row" className="UTTTD1">
                                        <Media className="align-items-center">
                                          {/* <a
                                              style={{ height: "1.979vw", width: "1.979vw" }}
                                              className="avatar rounded-circle mr-3"
                                              onClick={(e) => e.preventDefault()}
                                            >
                                              <img
                                                style={{ height: "1.979vw", width: "1.979vw" }}
                                                alt="..."
                                                className="rounded-circle"
                                                src={
                                                  require("../../../assets/img/theme/dummyImg.png")
                                                    .default
                                                }
                                              />
                                            </a> */}
                                          {user.photoUrl ? (
                                            <img
                                              className="rounded-circle mr-3"
                                              style={{
                                                width: "1.979vw",
                                                height: "1.979vw",
                                                // borderRadius:"50px",
                                              }}
                                              src={user.photoUrl}
                                            />
                                          ) : (
                                            <Avatar
                                              className="mr-3"
                                              style={{
                                                width: "1.979vw",
                                                height: "1.979vw",
                                                fontSize: "1.042vw",
                                                backgroundColor: "#CED4DA",
                                              }}
                                              alt={user.name}
                                              src="/"
                                            />
                                          )}
                                          <Media>
                                            <Link
                                              style={{ fontSize: "0.729vw" }}
                                              to={`/admin/Tutee/${user._id}`}
                                              onClick={() =>
                                                viewProfile(user, "tutee")
                                              }
                                            >
                                              {user.name}
                                            </Link>
                                          </Media>
                                        </Media>
                                      </th>
                                      <td
                                        className="UTTTD2"
                                        style={{ fontSize: "0.729vw" }}
                                      >
                                        {user.email}
                                      </td>

                                      <td
                                        className="UTTTD3"
                                        style={{ fontSize: "0.729vw" }}
                                      >
                                        {user.mobilePhone || "---"}
                                      </td>
                                      <td
                                        className="UTTTD4"
                                        style={{ fontSize: "0.729vw" }}
                                      >
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} */}
                                        {user.jobDetails.ongoing}
                                      </td>
                                      <td
                                        className="UTTTD5"
                                        style={{ fontSize: "0.729vw" }}
                                      >
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} */}
                                        {user.jobDetails.total}
                                      </td>
                                      <td
                                        className="UTTTD6"
                                        style={{
                                          color:
                                            user.status === "Active"
                                              ? "#06BE02"
                                              : "red",
                                          fontSize: "0.729vw",
                                        }}
                                      >
                                        {user.status === "Active" ? (
                                          <span>
                                            <i
                                              className="far fa-check-circle"
                                              style={{ fontSize: "0.729vw" }}
                                            ></i>{" "}
                                            Active{" "}
                                          </span>
                                        ) : (
                                          <span>
                                            <i
                                              className="fas fa-ban"
                                              style={{ fontSize: "0.729vw" }}
                                            ></i>
                                            &nbsp;Blocked
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-right UTTTD1">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            className="btn-icon-only text-light"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="fas fa-ellipsis-v" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="dropdown-menu-arrow"
                                            right
                                          >
                                            <Link
                                              to={`/admin/Tutee/${user._id}`}
                                              onClick={() =>
                                                viewProfile(user, "tutee")
                                              }
                                            >
                                              <DropdownItem>
                                                View Profile
                                              </DropdownItem>
                                            </Link>
                                            {getCurrentUserInfo()?.adminType ===
                                              "Admin" && (
                                              <DropdownItem
                                                onClick={(e) =>
                                                  editUser(user._id, {
                                                    user: {
                                                      status:
                                                        user?.status ===
                                                        "Active"
                                                          ? "Blocked"
                                                          : "Active",
                                                    },
                                                  })
                                                }
                                              >
                                                {user?.status === "Active"
                                                  ? "Block"
                                                  : "Unblock"}
                                              </DropdownItem>
                                            )}
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  ))
                              )}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                )}
                {tab === 2 && (
                  <TabPanel value={2}>
                    <div>
                      {showElement ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "30px",
                          }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "650px", overflowY: "scroll" }}>
                          <Table
                            className="align-items-center table-flush"
                            style={{ marginTop: "41px" }}
                            responsive
                          >
                            <thead
                              className="thead-light"
                              style={{
                                width: "100%",
                              }}
                            >
                              <tr>
                                <th scope="col" className="UATH1">
                                  FULL NAME
                                </th>
                                <th className="UATH1" scope="col">
                                  EMAIL
                                </th>

                                <th className="UATH1" scope="col">
                                  ADMIN TYPE
                                </th>
                                <th className="UATH1" scope="col">
                                  STATUS
                                </th>

                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    marginLeft: "10rem",
                                  }}
                                >
                                  <td colSpan={9}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ff6700"}
                                        height={30}
                                        width={30}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                allusers.map((user) => (
                                  <tr className="UTR">
                                    <td>
                                      <Media className="align-items-center">
                                        {user.photoUrl ? (
                                          <img
                                            className="rounded-circle mr-3"
                                            style={{
                                              width: "1.979vw",
                                              height: "1.979vw",
                                            }}
                                            src={user.photoUrl}
                                          />
                                        ) : (
                                          <Avatar
                                            className="mr-3"
                                            style={{
                                              width: "1.979vw",
                                              height: "1.979vw",
                                              fontSize: "1.042vw",
                                              backgroundColor: "#CED4DA",
                                            }}
                                            alt={user.name}
                                            src="/"
                                          />
                                        )}
                                        <Media
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Link
                                            style={{
                                              fontSize: "100%",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            {user.name}
                                          </Link>
                                          {user.email === loggedUser.email && (
                                            <Badge
                                              color="success"
                                              style={{
                                                fontSize: "55%",
                                                backgroundColor: "#12BF0F",
                                                height: "15px",
                                              }}
                                            >
                                              <span
                                                style={{ color: "#ffffff" }}
                                              >
                                                You
                                              </span>
                                            </Badge>
                                          )}
                                        </Media>
                                      </Media>
                                    </td>
                                    <td style={{ fontSize: "90%" }}>
                                      {user.email}
                                    </td>

                                    <td
                                      style={{
                                        fontSize: "90%",
                                      }}
                                    >
                                      {user?.adminType}
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          user.status === "Active"
                                            ? "#06BE02"
                                            : "red",
                                        fontSize: "80%",
                                      }}
                                    >
                                      {user.status === "Active" ? (
                                        <span>
                                          <i className="far fa-check-circle"></i>{" "}
                                          Active{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          <i className="fas fa-ban"></i>
                                          &nbsp;{user.status}
                                        </span>
                                      )}
                                    </td>
                                    {!isSuperAdmin &&
                                      user?._id ===
                                        getCurrentUserInfo()?._id && (
                                        <td className="text-right">
                                          <UncontrolledDropdown>
                                            <DropdownToggle
                                              className="btn-icon-only text-light"
                                              role="button"
                                              size="sm"
                                              color=""
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu
                                              className="dropdown-menu-arrow"
                                              right
                                            >
                                              <DropdownItem
                                                onClick={() => {
                                                  console.log({ user });
                                                  setOpen(true);
                                                  setState((prev) => ({
                                                    ...prev,
                                                    isEditMode: true,
                                                    selectedUser: user,
                                                    editUserInfo: {
                                                      firstName:
                                                        user?.name?.split(
                                                          " "
                                                        )[0],
                                                      lastName:
                                                        user?.name?.split(
                                                          " "
                                                        )[1],
                                                      email: user?.email,
                                                      adminType:
                                                        user?.adminType,
                                                    },
                                                  }));
                                                }}
                                              >
                                                Edit
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </td>
                                      )}

                                    {isSuperAdmin && (
                                      <td className="text-right">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            className="btn-icon-only text-light"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="fas fa-ellipsis-v" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="dropdown-menu-arrow"
                                            right
                                          >
                                            <DropdownItem
                                              onClick={async () => {
                                                await getActivityLogs(user._id);
                                                setOpen1(true);
                                              }}
                                            >
                                              See activity
                                            </DropdownItem>

                                            {isSuperAdmin && (
                                              <DropdownItem
                                                onClick={() => {
                                                  console.log({ user });
                                                  setOpen(true);
                                                  setState((prev) => ({
                                                    ...prev,
                                                    isEditMode: true,
                                                    selectedUser: user,
                                                  }));
                                                  switch (user.adminType) {
                                                    case "SuperAdmin":
                                                      setSuperAdmin(
                                                        !superAdmin
                                                      );
                                                      setWorker(false);
                                                      setAnalyst(false);
                                                      setAdminType(
                                                        "SuperAdmin"
                                                      );
                                                      return;

                                                    case "Worker":
                                                      setWorker(!worker);
                                                      setAnalyst(false);
                                                      setSuperAdmin(false);
                                                      setAdminType("Worker");
                                                      return;
                                                    case "Analyst":
                                                      setAnalyst(true);
                                                      setWorker(false);
                                                      setSuperAdmin(false);
                                                      setAdminType("Analyst");

                                                      return;

                                                    default:
                                                      return;
                                                  }
                                                }}
                                              >
                                                Edit
                                              </DropdownItem>
                                            )}

                                            {user._id !==
                                              getCurrentUserInfo()?._id && (
                                              <DropdownItem
                                                onClick={(e) =>
                                                  editUser(user._id, {
                                                    user: {
                                                      status:
                                                        user?.status ===
                                                        "Active"
                                                          ? "Blocked"
                                                          : "Active",
                                                    },
                                                  })
                                                }
                                              >
                                                {user?.status === "Active"
                                                  ? "Block"
                                                  : "Unblock"}
                                              </DropdownItem>
                                            )}
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    )}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                )}
              </TabsUnstyled>
            </Card>
          </div>
        </Row>
      </Container>

      <ReviewListModal
        isOpen={state.reviewModalIsOpen}
        handleModal={handleReviewModal}
        reviewsList={allusers[state.selectedTutoreIndex]?.reviewList}
      />
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => {
          setOpen(!isOpen);
          setState((prev) => ({
            ...prev,
            isEditMode: false,
            selectedUser: {},
          }));
        }}
      >
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>{state.isEditMode ? "Edit User" : "Adding a new admin user"}</h1>
          {!state.isEditMode && (
            <p style={{ color: "#9A9A9A" }}>
              New admin will sign up with the same email and password
            </p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 15px",
            fontWeight: "bold",
          }}
          className="modal-body"
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-30px ",
            marginBottom: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <div className="addAdminModal">
            <FormGroup
              onChange={(e) => {
                if (state.isEditMode) {
                  setState((prev) => ({
                    ...prev,
                    editUserInfo: {
                      ...prev.editUserInfo,
                      name: e.target.value,
                    },
                  }));
                } else setName(e.target.value);
              }}
              style={{ width: "100%" }}
              className="mb-1"
            >
              <InputGroup>
                <Input
                  type="text"
                  name="name"
                  className="mb-2"
                  placeholder="Name"
                  style={{ width: "90%" }}
                  defaultValue={state?.selectedUser["name"]}
                />
              </InputGroup>
            </FormGroup>
            {/* <FormGroup
              onChange={(e) => {
                if (state.isEditMode) {
                  setState((prev) => ({
                    ...prev,
                    editUserInfo: {
                      ...prev.editUserInfo,
                      lastName: e.target.value,
                    },
                  }));
                } else setLastName(e.target.value);
              }}
              style={{ width: "100%" }}
              className="mb-1"
            >
              <InputGroup>
                <Input
                  type="text"
                  name="lastName"
                  className="mb-2"
                  placeholder="Last Name"
                  style={{ width: "90%" }}
                  defaultValue={state?.selectedUser["name"]?.split(" ")[1]}
                />
              </InputGroup>
            </FormGroup> */}
            <FormGroup
              onChange={(e) => {
                if (state.isEditMode) {
                  setState((prev) => ({
                    ...prev,
                    editUserInfo: {
                      ...prev.editUserInfo,
                      email: e.target.value,
                    },
                  }));
                } else setEmail(e.target.value);
              }}
              style={{ width: "100%" }}
              className="mb-1"
            >
              <InputGroup>
                <Input
                  type="text"
                  name="email"
                  disabled={state.isEditMode}
                  className="mb-4"
                  placeholder="&#x2709; Email Address"
                  defaultValue={state?.selectedUser["email"]}
                  style={{ width: "90%" }}
                />
              </InputGroup>
            </FormGroup>
            <>
              <FormGroup
                onChange={(e) => {
                  if (state.isEditMode) {
                    setState((prev) => ({
                      ...prev,
                      editUserInfo: {
                        ...prev.editUserInfo,
                        password: e.target.value,
                      },
                    }));
                  } else setPassword(e.target.value);
                }}
                style={{ width: "100%" }}
                className="mb-1"
              >
                <InputGroup>
                  <Input
                    type="password"
                    name="password"
                    className="mb-4"
                    autoComplete="new-password"
                    placeholder="Password"
                    style={{ width: "90%" }}
                  />
                </InputGroup>
              </FormGroup>
              {!state.isEditMode && (
                <FormGroup
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ width: "100%" }}
                  className="mb-1"
                >
                  <InputGroup>
                    <Input
                      type="password"
                      name="setPassword"
                      className="mb-4"
                      placeholder="Confirm Password"
                      style={{ width: "90%" }}
                    />
                  </InputGroup>
                </FormGroup>
              )}
            </>
            {isSuperAdmin && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                className="checkBoxAddAdmin100"
              >
                <div>
                  <h5>Choose role</h5>
                </div>
                <div className="checkBoxAddAdmin">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label className="custom-checkbox">
                      <FormGroup
                        onChange={(e) => setAdminType("SuperAdmin")}
                        style={{ width: "100%" }}
                        className="mb-1"
                      >
                        <InputGroup>
                          <input
                            onChange={(e) => {
                              setSuperAdmin(!superAdmin);
                              setWorker(false);
                              setAnalyst(false);
                              setAdminType("SuperAdmin");
                              setState((prev) => ({
                                ...prev,
                                editUserInfo: {
                                  ...prev.editUserInfo,
                                  adminType: "SuperAdmin",
                                },
                              }));
                            }}
                            type="checkbox"
                            style={{ marginTop: "10px" }}
                            value={superAdmin}
                            // onClick={(e) => { setSuperAdmin(!superAdmin);console.log('super'); console.log(superAdmin,'superAdmin');}}
                            checked={superAdmin ? true : false}
                          />
                        </InputGroup>
                      </FormGroup>
                    </label>
                    <span style={{ marginLeft: "10px", marginTop: "-5px" }}>
                      Super Admin
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label className="custom-checkbox">
                      <FormGroup
                        onChange={(e) => {
                          setAdminType("Analyst");
                          console.log("analyst");
                        }}
                        style={{ width: "100%" }}
                        className="mb-1"
                      >
                        <InputGroup>
                          <input
                            style={{ marginTop: "10px" }}
                            value={analyst}
                            checked={analyst ? true : false}
                            onChange={() => {
                              setAnalyst(!analyst);
                              setWorker(false);
                              setSuperAdmin(false);
                              setAdminType("Analyst");
                              setState((prev) => ({
                                ...prev,
                                editUserInfo: {
                                  ...prev.editUserInfo,
                                  adminType: "Analyst",
                                },
                              }));
                            }}
                            type="checkbox"
                          />
                        </InputGroup>
                      </FormGroup>
                    </label>
                    <span style={{ marginLeft: "10px", marginTop: "-5px" }}>
                      Analyst
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label className="custom-checkbox">
                      <FormGroup
                        onChange={(e) => {
                          setAdminType("Worker");
                          console.log("worker");
                        }}
                        style={{ width: "100%" }}
                        className="mb-1"
                      >
                        <InputGroup>
                          <input
                            style={{ marginTop: "10px" }}
                            checked={worker ? true : false}
                            value={worker}
                            onChange={() => {
                              setWorker(!worker);
                              setAnalyst(false);
                              setSuperAdmin(false);
                              setAdminType("Worker");
                              setState((prev) => ({
                                ...prev,
                                editUserInfo: {
                                  ...prev.editUserInfo,
                                  adminType: "Worker",
                                },
                              }));
                            }}
                            type="checkbox"
                          />
                        </InputGroup>
                      </FormGroup>
                    </label>
                    <span style={{ marginLeft: "10px", marginTop: "-5px" }}>
                      Worker
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <Button
                style={{
                  backgroundColor: "#FF6700",
                  border: "1px solid #FF6700",
                  color: "#fff",
                  width: "100%",
                }}
                htmlType="submit"
                type="button"
                disabled={
                  state.isEditMode
                    ? false
                    : password === confirmPassword &&
                      password !== "" &&
                      email !== ""
                    ? false
                    : true
                }
                onClick={() => handleAddAdmin()}
              >
                {state.isEditMode ? "Save User" : "Add User"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen1}
        toggle={() => setOpen1(!isOpen1)}
        size="lg"
        style={{ maxWidth: "80%", width: "100%", minHeight: "50%" }}
      >
        <Card className="shadow">
          <CardBody style={{ minHeight: "600px" }}>
            <ActivityLog activitylogs={activitylogs} />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
export default Tables;
