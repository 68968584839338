import React, { useState, useEffect } from "react";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { toast } from "react-toastify";

import api from "../../services/api";

const VerifyEmail = (props) => {
  const [loading, setLoading] = useState(true);
  const [isVerified, setVerifyEmail] = useState(false);
  const [relatedApp, setRelatedApp] = useState([]);
  const [message, setMessage] = useState("");

  const path = String(props.location.pathname).split("/");
  const userId = path[2];

  async function emailVerification() {
    try {
      const apiCalling = await api.emailVerification(userId);
      console.log({ apiCalling });
      if (apiCalling?.status === 200) {
        setMessage(apiCalling?.data?.message);
        setVerifyEmail(true);
        setLoading(false);
      } else {
        setVerifyEmail(false);
        setLoading(false);
      }
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  async function installedApps() {
    try {
      //alert("running");
      const relatedApps = await navigator.getInstalledRelatedApps();
      //alert(relatedApps);

      setRelatedApp(relatedApps);
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  useEffect(async () => {
    if (userId) {
      emailVerification();
      installedApps();
    } else {
      return toast.error("Invalid User ID.");
    }
  }, []);

  const handleDone = () => {
    setTimeout(() => {
      window.close();
    }, 1000);
  };

  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <>
      {loading ? (
        <></>
      ) : isVerified || true ? (
        <VerifyEmailContent message={message} handleDone={handleDone} />
      ) : (
        alert("Could not fulfil your request at the moment.")
      )}
    </>
  );
};

export default VerifyEmail;

const VerifyEmailContent = ({
  message = "Your account has been verified successfully!",
  handleDone,
}) => {
  return (
    <div>
      <div className="main-content">
        <img
          alt="..."
          className="resetPasswordLogo"
          src={require("../../assets/img/brand/logo.svg").default}
        />

        <div className="header bg-gradient-reset-password py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6"></Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <h1 style={{ fontSize: "22px" }}>{message}</h1>
                  </div>

                  <div className="text-center" style={{ lineHeight: "1.5" }}>
                    <div>Please return back to the app and login. </div>
                  </div>

                  <div className="py-3 mx-7">
                    <Button
                      className="lButton reset_password_btn"
                      color="#2354d7"
                      type="button"
                      onClick={handleDone}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Done
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
