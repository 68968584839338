import React from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";

const JobTerminationReasonModal = ({
  isOpen,
  handleModal,
  handleMessage,
  performDeclineOperation,
}) => {
  return (
    <div>
      <Modal
        className="modal-dialog-centered BillingInvoiceModal2"
        toggle={(e) => {
          handleModal();
        }}
        isOpen={isOpen}
      >
        <div className="reviewContainer">
          <div className="reviewHeader">
            <h2>Job Termination Reason:</h2>

            <i
              style={{
                marginLeft: "30px",
                marginBottom: "30px",
                paddingTop: "3px",
                color: "#9A9A9A",
              }}
              onClick={() => handleModal()}
              className="fas fa-times"
            ></i>
          </div>

          <div>
            <FormGroup style={{ height: "20%" }} className="mb-0">
              <Input
                onChange={(e) => handleMessage(e.target.value)}
                type="textarea"
                style={{ wordWrap: "break-word", minHeight: "100px" }}
              />
            </FormGroup>
          </div>
          <div
            style={{
              float: "right",
              paddingTop: "20px",
            }}
          >
            <Button
              style={{ backgroundColor: "#F5365C", color: "#fff" }}
              type="button"
              onClick={() => {
                performDeclineOperation();
              }}
            >
              Decline
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobTerminationReasonModal;
