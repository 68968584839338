import React from "react";
import styles from "./styles.module.scss";

const Dropdown = ({ name, value, onChange, options }) => {
  return (
    <div>
      <select
        name={name}
        id={name}
        className={styles.dropdownField}
        value={value}
        onChange={onChange}
      >
        <option value="">Select</option>
        {options.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
