/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Card, CardHeader, Table, Container, Row, Button } from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useState, useEffect } from "react";
import api from "../../../services/api";

import { isEmpty } from "lodash";
import SendNotificationModal from "./SendNotificationModal";

const Tables = () => {
  const [isOpen, setOpen] = useState(0);
  const [tab, setTab] = useState(0);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [dropdown, setUser] = useState("");
  const [Membership1, setMemberShip1] = useState("");
  const [Membership2, setMemberShip2] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pushnotification, setPushNotification] = useState([]);
  const [allTutors, setAllTutors] = useState([]);
  const [allTutees, setAllTutees] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [selectedOption, setSelectedOption] = useState("All users");
  const [showMultiselect, setShowMultiselect] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };

  const getUsers = async () => {
    let allTutors = await api.getAllUsers("?userType=Tutor");
    let allTutees = await api.getAllUsers("?userType=Tutee");
    allTutors = allTutors.data.map((tt) => {
      return { name: tt.name, id: tt._id };
    });
    allTutees = allTutees.data.map((tt) => {
      return { name: tt.name, id: tt._id };
    });
    setAllTutors(allTutors);
    setAllTutees(allTutees);
  };
  useEffect(() => {
    getPushNotification();
    getUsers();
  }, []);

  async function getPushNotification() {
    try {
      const pushNotification = await api.getPushNotification("");

      if (!isEmpty(pushNotification.data)) {
        setPushNotification(pushNotification.data.reverse());
      }
    } catch (e) {
      console.log(e.response);
    }
  }

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
    // Check if the selected option is one of the desired options to show the Multiselect
    if (["CUSTOM_TUTOR", "CUSTOM_TUTEE"].includes(e.target.value)) {
      setShowMultiselect(true);
    } else {
      setShowMultiselect(false);
    }
  };

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const parts = inputDate.split("-");
    const year = parts[0];
    const month = months[parseInt(parts[1]) - 1];
    const day = parts[2];

    return `${day} ${month}, ${year}`;
  }

  const userTypes = {
    ALL_USER: "All Users",
    ALL_TUTEE: "All Tutee",
    ALL_TUTOR: "All Tutor",
    CUSTOM_TUTOR: "Custom Tutor User",
    CUSTOM_TUTEE: "Custom Tutee User",
  };

  function onSelect(selectedList, selectedItem) {
    setSelectedValues(selectedList);
  }

  const sendNotification = async () => {
    console.log({ selectedValues });
    const data = {
      title: title,
      body: message,
      message,
      type: "admin_notification",
      sendType: selectedOption,
    };
    if (selectedValues.length) {
      data.userIds = selectedValues.map((s) => s.id);
    }
    console.log({ data });
    const pushnotification = await api.postPushNotification(data);
    getPushNotification();
    setOpen(false);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className={window.innerWidth <= 1366 ? "mt--7" : "mt--8"}
        fluid
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Row style={{ boxSizing: "border-box" }}>
          <div className="col">
            <Card className="shadow" style={{ height: "75vh" }}>
              <CardHeader
                className="border-0"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "80px",
                }}
              >
                <h3 className="mb-0">All sent push notifications</h3>
                <Button
                  style={{
                    backgroundColor: "#FF6700",
                    border: "1px solid #FF6700",
                    color: "#fff",
                  }}
                  type="button"
                  onClick={() => setOpen(true)}
                >
                  Send New{" "}
                </Button>
              </CardHeader>

              <Table
                className="align-items-center table-flush"
                responsive
                style={{ minWidth: "80vw" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th
                      style={{
                        color: "#9A9A9A",
                        fontWeight: "bold",
                        fontSize: "12px",
                        width: "80%",
                        minWidth: "200px",
                        maxWidth: "400px",
                      }}
                      scope="col"
                    >
                      MESSAGE
                    </th>
                    <th
                      style={{
                        color: "#9A9A9A",
                        fontWeight: "bold",
                        fontSize: "12px",
                        width: "20%",
                      }}
                      scope="col"
                    >
                      SEND TO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pushnotification.map((pn) => (
                    <tr style={{ borderBottom: "1px solid #d3d3d3" }}>
                      <div style={{ marginTop: "12px", marginLeft: "16px" }}>
                        <b>{pn.title}</b>
                      </div>

                      <div
                        className="text-break"
                        style={{
                          fontSize: "0.9vw",
                          padding: "10px",
                          paddingLeft: "20px",
                          minWidth: "70vw",
                        }}
                      >
                        {pn.body}
                        <div
                          style={{
                            margin: "8px 0",
                            fontSize: "12px",
                            color: "#9A9A9A",
                          }}
                        >
                          {formatDate(pn.createdAt.substring(0, 10))}
                        </div>
                      </div>
                      <td
                        style={{
                          width: "20%",
                          color: "#9A9A9A",
                          fontWeight: "bold",
                          border: "none",
                          minWidth: "200px",
                        }}
                      >
                        {userTypes[pn.sendType]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>

        <SendNotificationModal
          isOpen={isOpen}
          setOpen={setOpen}
          allTutors={allTutors}
          allTutees={allTutees}
          setTitle={setTitle}
          onSelect={onSelect}
          setMessage={setMessage}
          selectedOption={selectedOption}
          showMultiselect={showMultiselect}
          sendNotification={sendNotification}
          handleDropdownChange={handleDropdownChange}
        />
      </Container>
    </>
  );
};

export default Tables;
