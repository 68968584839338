import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  Container,
  Row,
  Modal,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactLoading from "react-loading";

import { MyChat } from "views/pages/Chat/myChat";
import { AllChat } from "views/pages/Chat/allChat";
import InBoxServiceProvider from "../../views/pages/Chat/inBoxFBHook";

import MessageServiceProvider from "../../views/pages/Chat/messageBoxFBHook";
import { adminService } from "../../views/pages/Chat/inBoxFBHook";

import "./index.css";
import { disableCursor } from "@fullcalendar/react";

const SupportModal = ({ adminId, closeModal, loggedUser, job, user }) => {
  const [tab, setTab] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  // console.log(loggedUser,'loggedUser in support modal')
  // const [loading, setLoading] = useState(true);
  // const { isLoading } = adminService();
  // const loader = () => {
  //   console.log(loading, 'SUPPORT load TRUE ')
  // }
  // useEffect(() => {
  //   loader()
  // }, [loading])
  // console.log(isLoading, 'ISLOADING SUPPORT above')

  useEffect(() => {
    // directMessageBox()
    // document
    //   ?.querySelector(".pageCoverSupport")
    //   ?.scrollTop.classList.remove("disable-scroll");
    // document.body.style[i].overflow = "hidden"
  }, []);

  function directMessageBox() {
    if (user == "tutor") {
      if (adminId == job?.tutor[0]?.adminId) {
        // console.log(job, 'job tutor SUPPORT MODAL')
        // console.log(user, 'user LOADING SUPPORT MODAL')
      } else {
        //  console.log(user, 'user else first tutor LOADING SUPPORT MODAL')
      }
    } else {
      if (adminId == job?.tutee[0]?.adminId) {
        // console.log(job, 'job tutee SUPPORT MODAL')
        // console.log(user, 'user LOADING SUPPORT MODAL')
      } else {
        // console.log(user, 'user else second tutee LOADING SUPPORT MODAL')
      }
    }
    // else {
    // console.log(job, 'job LOADING SUPPORT MODAL')
    //   console.log(user, 'user LOADING SUPPORT MODAL')
    //   console.log(loggedUser, 'loggedUser LOADING SUPPORT MODAL')
    // console.log(adminId, 'loggedUser LOADING SUPPORT MODAL')
    // }

    // if (adminId === loggedUser._id) {
    //   console.log('adminid')
    // } else {
    //   console.log('else adminid')
    // }
    // console.log(job, 'job LOADING SUPPORT MODAL')
    // console.log(adminId, 'loggedUser ADMIN LOADING SUPPORT MODAL')
    // console.log(job?.tutee[0]?.adminId, 'admin id from job TUTEE')
    // console.log(job?.tutor[0]?.adminId, 'admin id from job TUTOR')
    // console.log(user, 'user')
  }
  function handleModalClose() {
    setIsOpen(!isOpen);
    closeModal(false);
  }
  return (
    <div className="pageCoverSupport" id="pageCoverSupport">
      <div
        className="darkpageCoverSupport"
        onClick={() => handleModalClose()}
      ></div>
      <div className="mainSupportModel">
        <div className="SupportBox">
          <button className="SuppInboxClose" onClick={() => handleModalClose()}>
            <img
              alt="..."
              src={require("../../assets/img/icons/common/Vector.png").default}
            />
          </button>
          <div className="SuppInboxText">Support Inbox</div>
          <div onClick={() => setTab(0)}>
            <div
              className={
                tab === 0
                  ? "buttonTabs11 underLine cursor-pointer"
                  : "buttonLight11 cursor-pointer"
              }
            >
              My Inbox
            </div>
          </div>
          <div onClick={() => setTab(1)}>
            <div
              className={
                tab === 1
                  ? "buttonTabs11 underLine cursor-pointer"
                  : "buttonLight11 cursor-pointer"
              }
            >
              All Chats
            </div>
          </div>
        </div>
        <div className="SuppDivider">
          <div className="divider"></div>
        </div>
        {tab === 0 && (
          <div className="PrimaryBox">
            <div>
              {/* {
                loading === true ? (
                  <div><ReactLoading
                    type={"spin"}
                    color={"#ff6700"}
                    height={30}
                    width={30} />
                  </div>
                ) : ( */}
              <InBoxServiceProvider>
                <MyChat
                  chatType="myChat"
                  adminId={adminId}
                  loggedUser={loggedUser}
                />
              </InBoxServiceProvider>
              {/* )
              } */}
            </div>
          </div>
        )}
        {tab === 1 && (
          <div className="PrimaryBox11">
            <div className="">
              <div className="SuppAllChatAgent">
                <div className="SuppAllChantAgentText">Admin Agent</div>
                <div className="SuppAllChatArrows">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/interChangeArrows.png")
                        .default
                    }
                  />
                </div>
                <div className="SuppAllChantAgentText">App User</div>
              </div>
            </div>
            {/* {
            loading === true ? (

              <div><ReactLoading
                type={"spin"}
                color={"#ff6700"}
                height={30}
                width={30} />
              </div>
              ) : ( 
              <AllChat chatType="allChat" loading={() => { setLoading(false) }} loggedUser={loggedUser} />
              */}
            <InBoxServiceProvider>
              <AllChat chatType="allChat" loggedUser={loggedUser} />
            </InBoxServiceProvider>
            {/* )
          } */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportModal;
