/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Row,
  UncontrolledTooltip,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Col,
  Modal,
  Spinner,
} from "reactstrap";
import BookIcon from "@mui/icons-material/Book";
import { toast } from "react-toastify";

import React, { useState, useEffect, useRef } from "react";
import Profilecardtutor from "components/Profilecard/Profilecardtutor.js";
import Schedule from "components/Schedule/Schedule.js";
import api from "../../services/api";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import ActivityLog from "../../components/ActivityLog/ActivityLogProfile";
import BankAccontDetail from "../../components/BankAccountDetails/BankAccountDetail";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import Multiselect from "multiselect-react-dropdown";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";

import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { JobStatusEnum } from "helpers/common-helper";

import "./index.css";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, firebaseStorage } from "./Chat/firebase-config";
import { Path } from "services/Path";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

class TableRow extends React.Component {
  state = { expanded: false };

  toggleExpander = (e) => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { bill, invoices } = this.props;
    return [
      <>
        <tr>
          <>
            <th scope="row">
              <Media className="align-items-center">
                <BookIcon
                  sx={{
                    color: bill?.classes[0]?.courseColor
                      ? bill.classes[0].courseColor
                      : " #ced4da",
                    width: "2.083vw",
                    height: "2.083vw",
                  }}
                />

                <td style={{ width: "15%", border: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignSelf: "center",
                    }}
                  >
                    <a
                      style={{ fontSize: "0.729vw" }}
                      href={`/#/admin/TutionDetails/${bill?.job[0]?._id}`}
                    >
                      {bill?.job[0]?.courseName}
                    </a>
                    <span style={{ color: "grey", fontSize: "80%" }}>
                      {bill?.job[0]?.courseLvl}
                    </span>
                  </div>
                </td>
              </Media>
            </th>
            <td style={{ fontSize: "80%" }}>
              {bill?.job[0]?.courseDuration || "N/A"}
            </td>
            <td style={{ fontSize: "80%" }}>${bill?.job[0]?.priceperHour}</td>
            <td style={{ fontSize: "80%" }}>
              {bill?.totalClassDuration} Hours
            </td>
            <td style={{ fontWeight: "bold", fontSize: "70%" }}>
              {bill?.invoices[0]?.month}
            </td>
            <td>{bill?.invoices[0]?.noOfClasses || 0}</td>
            <td>${bill?.invoices[0]?.amount}</td>
            <td>
              ${bill?.invoices[0]?.amount - bill?.invoices[0]?.remainingAmount}
            </td>
            <td>${bill?.invoices[0]?.remainingAmount}</td>
            <td>${bill.invoices[0]?.salary}</td>
            {/* <td>
              {bill.invoices[0]?.jobDetails?.postponed}
            </td> */}
            <td>{bill.invoices[0]?.paymentStatus}</td>
            <td>{JobStatusEnum(bill?.job[0]?.jobStatus).view}</td>

            <td className="text-right"></td>
            <td className="text-right">
              {!this.state.expanded ? (
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingRight: "20px",
                  }}
                  onClick={() => this.toggleExpander()}
                >
                  <i className="fas fa-chevron-down"></i>
                </button>
              ) : (
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => this.toggleExpander()}
                >
                  {" "}
                  <i className="fas fa-chevron-up"></i>
                </button>
              )}
            </td>
          </>
        </tr>
        {this.state.expanded
          ? invoices.length &&
            invoices.map(
              (inv, ind) =>
                ind !== 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: "bold", fontSize: "70%" }}>
                      {inv.month}
                    </td>
                    <td>{bill?.invoices[ind]?.noOfClasses}</td>

                    <td>{bill?.invoices[ind]?.amount}</td>
                    <td>
                      $
                      {bill?.invoices[ind]?.amount -
                        bill?.invoices[ind]?.remainingAmount}
                    </td>
                    <td style={{ fontSize: "70%" }}>
                      {bill?.invoices[ind]?.remainingAmount}
                    </td>
                    <td style={{ fontSize: "70%" }}>
                      {bill.invoices[ind]?.salary}
                    </td>
                    <td style={{ fontSize: "70%" }}>
                      {bill.invoices[ind]?.paymentStatus}
                    </td>
                    <td>{JobStatusEnum(bill?.job[0]?.jobStatus).view}</td>

                    {/* <td></td> */}
                    <td className="text-right"></td>
                    <td className="text-right"></td>
                  </tr>
                )
            )
          : null}
      </>,
      ,
    ];
  }
}
const Tables = (props) => {
  const [rowOpen, setRowOpen] = useState(false);
  // const [state, setState] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [user, setUser] = useState({});
  const [tab, setTab] = useState(0);
  const [isOpen1, setOpen1] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [isOpen3, setOpen3] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState({});
  const [userId, setUserId] = useState(props.match.params.id);

  const [school, setSchool] = useState("");
  const [areaOfStudy, setAreaOfStudy] = useState("");
  const [degree, setDegree] = useState("");
  const [document, setDocument] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [loggedUser, setLoggedInUser] = useState(false);
  const [activitylogs, setActivityLogs] = useState([]);
  const [billing, setBilling] = useState([]);
  const [state, setState] = useState({
    totalEarnings: 0,
    isLoading: false,
  });

  const certificateRef = useRef(null);
  // const [loggedUser, setLoggedInUser] = useState([]);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const options = [
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ];

  const myInput = React.createRef();

  const file =
    "https://image.freepik.com/free-psd/modern-certificate-achievement-psd-template_69286-125.jpg";
  const type = "jpg";

  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setLoggedInUser(user);
    getAllJobs();
    getUsersById();
    getUserSchedule();
    getActivityLogs();
    getBilling();
  }, []);

  async function getUserSchedule() {
    try {
      const schedule = await api.getUserSchedule(`?tutorId=${userId}`);
      // if (!schedule.length) {
      const newSchedule = schedule.data.map((sch) => {
        return {
          ...sch,
          color: sch.courseColor,
          oldDate: sch?.date,
          date: sch?.newDate ?? sch?.date,
          // title:sch.
        };
      });
      await setSchedule(newSchedule);
      // }
    } catch (e) {}
  }

  async function getAllJobs() {
    try {
      const allJobs = await api.getAllJobs("");
      if (!allJobs.length) {
        await setAllJobs(allJobs.data);
      }
    } catch (e) {}
  }
  async function getUsersById() {
    try {
      const user = await api.getUserById(
        userId,
        "?userType=Tutor&reviewDetail=true&qualification=true&jobDetail=true"
      );
      setUser(user.data);
      GetAllCourses(user.data);
    } catch (e) {}
  }

  async function editUser(data) {
    try {
      const user = await api.editUser(userId, data);
      await setUser(user.data);
      getUsersById();
    } catch (e) {}
  }

  async function approveQualification(id, data) {
    if (data?.status === "Rejected" && message?.length === 0) {
      return toast.error("Rejection Reason is required!");
    }
    try {
      const qualification = await api.editQualification(id, data);
      setSelectedQualification(qualification.data);
      getUsersById();
      setOpen(false);
    } catch (e) {}
  }
  async function deleteQualification(id) {
    try {
      const qualification = await api.deleteQualification(id);
      getUsersById();
    } catch (e) {
      getUsersById();
    }
  }
  async function getBilling(tab, startDate) {
    try {
      let paymentMethod = "";
      if (tab == 1) {
        paymentMethod = "GIRO";
      }
      if (tab == 2) {
        paymentMethod = "Debit/Credit%20Card";
      }
      if (tab == 3) {
        paymentMethod = "Bank%20Transfer";
      }
      const billing = await api.getBilling(`?tutorId=${userId}&jobDetail=true`);
      calculateTotalEarnings(billing.data);
      setBilling(billing.data);
    } catch (e) {}
  }
  async function addEducation(id, data) {
    try {
      const data = {
        school,
        areaOfStudy,
        degree,
        endDate,
        document,
        status: "Approved",
        tutorId: userId,
        adminId: loggedUser._id,
      };

      if (school) {
        if (areaOfStudy) {
          if (degree) {
            if (endDate) {
              if (document) {
                const qualification = await api.addQualification(data);
                getUsersById();
                updateEducation();
                setErrMsg("");
                setOpen2(false);
              } else {
                setErrMsg("Document is missing.");
              }
            } else {
              setErrMsg("End Date is missing.");
            }
          } else {
            setErrMsg("Degree is missing.");
          }
        } else {
          setErrMsg("Area of study is missing.");
        }
      } else {
        setErrMsg("School name is missing.");
      }
    } catch (e) {}
  }

  async function GetAllCourses(user) {
    try {
      const getAllCourses = await api.getAllCourses("");
      if (!getAllCourses.length) {
        const unique = [];
        const courses = getAllCourses.data.map((c, ind) => {
          unique.push(c.courseName);
          return {
            id: ind + 1,
            name: c.courseName,
          };
        });
        await setAllCourses(courses);
        const selectedValues = [];
        let num = 1;
        user?.user?.subjects.map((sub) => {
          if (unique.includes(sub)) {
            selectedValues.push({ id: num, name: sub });
            num++;
          }
        });
        setSelectedValues(selectedValues);
      }
    } catch (e) {}
  }

  async function editUser(id, data) {
    try {
      const user = await api.editUser(id, data);
      await setUser(user.data);
      getUsersById();
    } catch (e) {}
  }

  function onSelect(selectedList, selectedItem) {
    let subjects = selectedList.target.value;
    editUser(user?.user?._id, {
      user: {
        subjects,
      },
    });
    updateEducation();
  }

  function onRemove(selectedList, removedItem) {
    let subjects = selectedList.map((s) => s.name);
    editUser(user?.user?._id, {
      user: {
        subjects,
      },
    });
    updateEducation();
  }

  function closeCalendar() {
    setOpen3(false);
    getUserSchedule();
  }
  function onError(e) {
    console.error(e, "error in file-viewer");
  }

  function getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async function updateEducation() {
    const data = {
      adminId: loggedUser._id,
      userId: userId,
      activityType: "Update Education",
      message: user.user.userType,
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }

  async function getActivityLogs() {
    try {
      const activitylogs = await api.getAllActivitylog(`?userId=${userId}`);
      if (!activitylogs.length) {
        await setActivityLogs(activitylogs.data);
      }
    } catch (e) {}
  }

  function calculateTotalEarnings(tutorBilling) {
    let totalEarnings = 0;

    tutorBilling?.result?.map((record) => {
      let isBreak = false;

      if (record?.job[0]?.jobStatus !== 1) {
        for (let i = 0; i < record.invoices.length; i++) {
          if (isBreak) {
            break;
          }
          if (
            record.invoices[i]?.month ===
            moment(new Date()).format("MMMM, YYYY")
          ) {
            totalEarnings += record.invoices[i]?.salary;
            isBreak = true;
            continue;
          } else {
            totalEarnings += record.invoices[i]?.salary;
          }
        }
      }
    });

    setState((prev) => ({
      ...prev,
      totalEarnings,
    }));
  }

  const handleCertifcateFile = (e) => {
    const inputFile = e.target?.files;
    handleUpload(inputFile[0]);
  };

  const handleUpload = async (file) => {
    if (file) {
      try {
        setState((prev) => ({
          ...prev,
          isLoading: true,
        }));
        // Upload the file to Firestore Storage
        const storageRef = ref(
          firebaseStorage,
          `tutor-certificates/${file.name}`
        );

        let folderName = Path.QUALIFICATION?.includes("dev")
          ? "Dev-Tutor-Certs"
          : "Live-Tutor-Certs";

        await uploadBytes(storageRef, file);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(storageRef);

        // Add file information to Firestore
        const filesCollection = collection(db, folderName);
        let resp = await addDoc(filesCollection, {
          fileName: `${userId}/certificate/${file.name}`,
          downloadURL: downloadURL,
          timestamp: serverTimestamp(),
        });
        setDocument(downloadURL);

        setState((prev) => ({
          ...prev,
          isLoading: false,
          uploadedCertificate: downloadURL,
        }));
        console.log("File uploaded successfully!");
      } catch (error) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        console.error("Error uploading file:", error);
      }
    } else {
      console.warn("No file selected for upload.");
    }
  };

  const { classes } = props;
  const subjects = ["physics", "mathematics", "chemistry"];
  return (
    <div>
      <Header />
      <div className="mt--7" fluid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "3.125vw",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "26.042vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <Profilecardtutor
                user={user}
                totalEarnings={state?.totalEarnings}
                editUser={editUser}
                getUsersById={getUsersById}
              />
            </div>
            <div style={{ marginTop: "1.563vw" }}>
              <div
                className="card-profile shadow"
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0.625vw",
                  width: "26.406vw",
                  minHeight: "16.927vw",
                }}
              >
                <div
                  className=" text-center border-0"
                  style={{
                    text: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "0px",
                    padding: "1.250vw 2.188vw 0.990vw 1.563vw",
                    height: "3.802vw",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.938vw",
                      fontWeight: 600,
                      color: "#454545",
                    }}
                    className="mb-0"
                  >
                    Qualifications
                  </div>
                  {tab === 0 ? (
                    <div>
                      <div
                        onClick={() => setOpen2(true)}
                        style={{ fontSize: "80%", cursor: "pointer" }}
                        className="buttonTabs1"
                      >
                        Add New
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "inherit",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        marginLeft: "1.563vw",
                      }}
                    >
                      <div
                        className={tab === 0 ? "tab1 underline1" : "tab2"}
                        style={{ fontSize: "0.729vw", cursor: "pointer" }}
                        onClick={() => setTab(0)}
                      >
                        Education
                      </div>
                      <div
                        className={tab === 1 ? "tab1 underline1" : "tab2"}
                        style={{
                          marginLeft: "1.042vw",
                          fontSize: "0.729vw",
                          cursor: "pointer",
                        }}
                        onClick={() => setTab(1)}
                      >
                        Subject
                      </div>
                    </div>
                    <hr
                      style={{ marginBottom: "0.521vw", marginTop: "0.469vw" }}
                    ></hr>
                  </div>
                  {tab === 0 ? (
                    <div className="PrimaryScrollQualification">
                      {user?.qualification?.map((qual) => (
                        <>
                          <div className="qualificationRequest">
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <h4
                                  style={{ fontSize: "0.833vw" }}
                                  className="mb-0"
                                >
                                  {qual.degree}
                                </h4>
                                {qual.status === "Pending" ? (
                                  <Badge
                                    style={{
                                      fontSize: "0.833vw",
                                      backgroundColor: "#5E72E4",
                                      color: "#fff",
                                      marginLeft: "5px",
                                      paddingTop: "7px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    New Request
                                  </Badge>
                                ) : qual.status === "Rejected" ? (
                                  <>
                                    <Badge
                                      style={{
                                        fontSize: "0.833vw",
                                        backgroundColor: "red",
                                        color: "#fff",
                                        marginLeft: "5px",
                                        paddingTop: "7px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Rejected
                                    </Badge>
                                  </>
                                ) : (
                                  <i
                                    style={{
                                      color: "#12BF0F",
                                      fontSize: "0.833vw",
                                      marginLeft: "5px",
                                      marginTop: "3px",
                                      alignItems: "center",
                                    }}
                                    className="fas fa-check-circle"
                                  ></i>
                                )}
                              </div>
                              <span
                                style={{
                                  fontSize: "0.729vw",
                                  color: "#9A9A9A",
                                }}
                                className="description"
                              >
                                {qual?.endDate?.substring(0, 4)} - {qual.school}
                                <br />
                                {qual.status === "Rejected" && (
                                  <span
                                    style={{
                                      fontSize: "0.729vw",
                                      color: "#9A9A9A",
                                    }}
                                    className="description"
                                  >
                                    {qual?.message}
                                  </span>
                                )}
                              </span>
                            </div>
                            {qual.status === "Pending" ? (
                              <span
                                style={{
                                  float: "right",
                                  fontSize: "95%",
                                  // marginRight: "1.042vw",
                                  cursor: "pointer",
                                }}
                                className="mb-0 text-sm"
                                // href="#pablo"
                                onClick={async () => {
                                  setSelectedQualification(qual);
                                  setOpen(true);
                                }}
                              >
                                Verify
                              </span>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  onClick={() => {
                                    setSelectedQualification(qual);
                                    setOpen1(true);
                                  }}
                                  style={{
                                    color: "grey",
                                    fontSize: "0.833vw",
                                  }}
                                  className="far fa-eye"
                                ></i>
                                &nbsp;&nbsp;
                                <i
                                  style={{
                                    color: "grey",
                                    fontSize: "0.833vw",
                                  }}
                                  onClick={() => {
                                    deleteQualification(
                                      selectedQualification._id
                                    );
                                  }}
                                  className="far fa-trash-alt"
                                ></i>
                              </div>
                            )}
                          </div>
                          <hr
                            style={{
                              marginBottom: "0.521vw",
                              marginTop: "0.521vw",
                            }}
                          />
                        </>
                      ))}
                    </div>
                  ) : (
                    <>
                      <hr
                        style={{
                          marginBottom: "0.521vw",
                          marginTop: "0.521vw",
                        }}
                      ></hr>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                          <div>
                            {null}
                            <FormControl sx={{ m: 1, width: "25.365vw" }}>
                              <InputLabel id="demo-multiple-chip-label">
                                Add Subjects
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                onChange={onSelect}
                                value={selectedValues.map((v) => v.name)}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Subjects"
                                  />
                                }
                                renderValue={() => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selectedValues.map((value) => (
                                      <Chip
                                        style={{ fontSize: "0.625vw" }}
                                        key={value.id}
                                        label={value.name}
                                      />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {allCourses.map((val) => (
                                  <MenuItem
                                    key={val.id}
                                    value={val.name}
                                    style={getStyles(
                                      val.name,
                                      personName,
                                      theme
                                    )}
                                  >
                                    {val.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <tbody></tbody>
              </div>
            </div>
          </div>

          <div
            xs={12}
            md={6}
            lg={6}
            xl={7}
            style={{
              marginLeft: "1.563vw",
              width: "52.813vw",
              height: "40.833vw",
              marginRight: "3.125vw",
            }}
          >
            {/* <Card
              className="shadow"
              style={{ maxHeight: "808px", minHeight: "808px" }}
            > */}
            <div
              style={{ backgroundColor: "#FFFFFF", borderRadius: "0.625vw" }}
              className="card-profile shadow"
            >
              <div
                className=" text-center border-0"
                style={{
                  padding: "1.042vw 1.563vw",
                  text: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minHeight: "0.521vw",
                }}
              >
                <h3 className="mb-0">Schedule</h3>
                {/* <div>
                    <div
                      onClick={() => setOpen3(true)}
                      style={{
                        fontSize: "0.8vw",
                        width: "11.042vw",
                        marginLeft: "-5px",
                      }}
                      className="buttonTabs1"
                    >
                      <i
                        style={{ paddingRight: "5px", marginLeft: "-7px" }}
                        className="far fa-calendar-alt"
                      ></i>
                      Reschedule class
                    </div>
                </div> */}
              </div>
              <div
                style={{
                  height: "37.2vw",
                  display: "flex",
                }}
              >
                <Schedule schedule={schedule} />
              </div>
            </div>
            {/* </Card> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "3.125vw",
          }}
        >
          <div
            style={{ borderRadius: "0.625vw", width: "52.813vw" }}
            xs={12}
            md={8}
            lg={8}
            xl={8}
          >
            <div
              className="shadow"
              style={{
                width: "52.813vw",
                height: "23.750vw",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.625vw",
                marginTop: "1.563vw",
              }}
            >
              <ActivityLog activitylogs={activitylogs} />
            </div>
          </div>
          <div style={{ width: "26.406vw" }} xs={12} md={4} lg={4} xl={4}>
            <div
              className="card-profile shadow"
              style={{
                marginLeft: "1.563vw",
                width: "26.406vw",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.625vw",
                marginTop: "1.563vw",
                maxHeight: "23.750vw",
                minHeight: "23.750vw",
              }}
            >
              <BankAccontDetail user={user} userId={user?.user?._id} />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginLeft: "3.125vw",
          marginTop: "1.563vw",
          width: "80.729vw",
        }}
      >
        <div>
          <div
            style={{ backgroundColor: "#FFFFFF", borderRadius: "0.625vw" }}
            className="shadow"
          >
            <div
              className="border-0"
              style={{
                margin: "0px",
                padding: "1.250vw 1.563vw",
                height: "3.802vw",
              }}
            >
              <h3
                style={{
                  minHeight: "1.563vw",
                  fontSize: "0.938vw",
                  color: "#454545",
                  fontWeight: 600,
                }}
                className="mb-0"
              >
                All tutions
              </h3>
            </div>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">TUITION</th>
                  <th scope="col">Duration </th>
                  <th scope="col">Hourly Rate</th>
                  <th scope="col">CLASS Duration</th>
                  <th scope="col">Month</th>
                  <th scope="col">Total Classes</th>

                  <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                    Total Bill
                  </th>
                  <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                    Paid
                  </th>
                  <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                    Pending
                  </th>
                  <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                    Tutor Salary
                  </th>

                  <th style={{ backgroundColor: "##E1F0FF" }} scope="col">
                    Status
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "0.521vw",
                      fontWeight: 600,
                      paddingRight: "0rem",
                    }}
                  >
                    Job Status
                  </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#EFFFF8" }}>
                {billing?.result?.map((bill, index) => (
                  <TableRow
                    bill={bill}
                    key={index}
                    index={index + 1}
                    invoices={bill?.invoices}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => setOpen(!isOpen)}
      >
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>Reschedule class</h1>
          <p style={{ color: "#9A9A9A" }}>
            Choose from below options to reschedule a class
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0.521vw 15px",
            fontWeight: "bold",
          }}
          className="modal-body"
        ></div>

        <div style={{ margin: "-15px 1.563vw", marginLeft: "3.125vw" }}>
          <h5> Reschedule type</h5>

          <div
            style={{
              marginTop: "15px ",
              marginBottom: "15px ",
              marginRight: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="custom-control custom-checkbox mb-3 ">
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
              />
              <label className="custom-control-label " htmlFor="customCheck1">
                <span style={{ marginLeft: "-1.042vw" }}> Date</span>
              </label>
            </div>
            <div
              style={{ marginLeft: "-40px" }}
              className="custom-control custom-checkbox mb-3"
            >
              <input
                className="custom-control-input"
                defaultChecked
                id="customCheck2"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="customCheck2">
                <span style={{ marginLeft: "-1.042vw" }}> Time</span>
              </label>
            </div>
            <div
              style={{ marginLeft: "-32px" }}
              className="custom-control custom-checkbox mb-3"
            >
              <input
                className="custom-control-input"
                defaultChecked
                id="customCheck3"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="customCheck3">
                <span style={{ marginLeft: "-1.042vw" }}> Both </span>
              </label>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "1.563vw" }}>
          <div style={{ margin: "0.521vw 1.563vw" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "0.521vw",
                marginRight: "0.521vw",
                marginBottom: "0.521vw",
                width: "80%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
            <div style={{ marginTop: "-43px", marginLeft: "42%" }}>
              <i
                style={{ color: "#9A9A9A" }}
                className="fas fa-arrow-right"
              ></i>
            </div>
          </div>
          <div style={{ marginTop: "-15.5%", marginLeft: "50%" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "0.521vw",
                marginRight: "0.521vw",
                marginBottom: "0.521vw",
                width: "150%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
          </div>
        </div>

        <div style={{ marginLeft: "1.563vw" }}>
          <div style={{ margin: "0.521vw 1.563vw" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "0.521vw",
                marginRight: "0.521vw",
                marginBottom: "0.521vw",
                width: "80%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
            <div style={{ marginTop: "-43px", marginLeft: "42%" }}>
              <i
                style={{ color: "#9A9A9A" }}
                className="fas fa-arrow-right"
              ></i>
            </div>
          </div>
          <div style={{ marginTop: "-15.5%", marginLeft: "50%" }}>
            <h5>From date</h5>
            <div
              style={{
                marginTop: "0.521vw",
                marginRight: "0.521vw",
                marginBottom: "0.521vw",
                width: "150%",
                height: "50%",
              }}
            >
              <FormGroup style={{ width: "45%" }} className="mb-0">
                <Input type="text" />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Link
            style={{
              width: "100%",
              margin: "1.042vw 0.521vw",
              marginLeft: "-0.938vw",
            }}
          >
            <div
              style={{ height: "50px", paddingTop: "0.625vw" }}
              className="modalFooter"
            >
              Update Schedule
            </div>
          </Link>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => setOpen(!isOpen)}
      >
        <CardHeader className="border-0">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <i
              size="lg"
              style={{
                color: "#FF8A3C",
                paddingRight: "4px",
                fontSize: "3.125vw",
                marginTop: "-15px",
              }}
              className="fas fa-graduation-cap"
            ></i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "5px",
                textAlign: "left",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ marginBottom: "-5px" }}>
                  {" "}
                  Verify Education Request{" "}
                </h5>
                <i
                  style={{
                    marginLeft: "200px",
                    paddingTop: "3px",
                    color: "#9A9A9A",
                  }}
                  onClick={() => setOpen(false)}
                  className="fas fa-times"
                ></i>
              </div>

              <p
                style={{
                  color: "#9A9A9A",
                  textAlign: "left",
                  fontSize: "75%",
                }}
              >
                Cross check the education details with attached certificate and
                respond
              </p>
            </div>
          </div>
        </CardHeader>
        <div
          style={{
            justifyContent: "space-around",
            display: "flex",
            backgroundColor: "#F6F9FC",
            paddingTop: "1.042vw",
            paddingBottom: "1.042vw",
          }}
        >
          <div>
            <div>
              <h5 style={{ color: "#757575" }}>Holder name </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {user?.user?.name}
              </h5>
            </div>
            <div>
              <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                Education title{" "}
              </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {selectedQualification?.degree}
              </h5>
            </div>
            <div>
              <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                School / University
              </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {selectedQualification?.school}
              </h5>
            </div>
            <div>
              <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                Completion Year
              </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {selectedQualification?.endDate?.substring(0, 4)}
              </h5>
            </div>
          </div>
          <div style={{ marginLeft: "100px" }}>
            <h5>REVIEW ATTACHMENT</h5>

            <div className="con">
              <div className="overlay">
                {/* <p style={{ fontSize: "0.833vw", fontWeight: "700" }}>
                  myCertificate.pdf
                </p> */}
                <a href={selectedQualification?.document} target="_blank">
                  <p
                    style={{
                      fontSize: "0.729vw",
                      color: "#FFCB95",
                      fontWeight: "400",
                    }}
                  >
                    (Click to review)
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ float: "right", paddingTop: "8px", marginLeft: "77%" }}>
            <Button
              color="success"
              type="button"
              onClick={() => {
                approveQualification(selectedQualification._id, {
                  status: "Approved",
                });
              }}
            >
              Approve
            </Button>
          </div>
          <div>
            <h5 style={{ marginLeft: "1.302vw" }}>
              Reason of Rejection (Required)
            </h5>
            <div
              style={{
                marginLeft: "1.302vw",
                marginBottom: "0.521vw",
                width: "185%",
                height: "50%",
              }}
            >
              <FormGroup
                style={{ width: "50%", height: "20%" }}
                className="mb-0"
              >
                <Input
                  onChange={(e) => setMessage(e.target.value)}
                  type="textarea"
                  style={{ wordWrap: "break-word", height: "100px" }}
                />
              </FormGroup>
            </div>
          </div>
          <div
            style={{
              float: "right",
              paddingTop: "8px",
              marginLeft: "80%",
              marginBottom: "0.521vw",
            }}
          >
            <Button
              style={{ backgroundColor: "#F5365C", color: "#fff" }}
              type="button"
              onClick={() => {
                approveQualification(selectedQualification._id, {
                  status: "Rejected",
                  message: message,
                });
                // deleteQualification(selectedQualification._id);
              }}
            >
              Reject
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen1}
        toggle={() => setOpen1(!isOpen1)}
      >
        <CardHeader className="border-0">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <i
              style={{
                color: "#FF8A3C",
                paddingRight: "4px",
                fontSize: "3.125vw",
                marginTop: "-2px",
              }}
              className="fas fa-graduation-cap"
            ></i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "5px",
                textAlign: "left",
                marginTop: "7px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ marginBottom: "-2px" }}> Education </h3>
                <i
                  style={{
                    marginLeft: "270px",
                    paddingTop: "3px",
                    color: "#9A9A9A",
                  }}
                  onClick={() => setOpen1(false)}
                  className="fas fa-times"
                ></i>
              </div>

              <p
                style={{
                  color: "#9A9A9A",
                  textAlign: "left",
                  fontSize: "75%",
                }}
              >
                Showing education details of tutor
              </p>
            </div>
          </div>
        </CardHeader>
        <div
          style={{
            justifyContent: "space-around",
            display: "flex",
            backgroundColor: "#F6F9FC",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <div>
            <div>
              <h5 style={{ color: "#757575" }}>Education title </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {selectedQualification?.areaOfStudy}
              </h5>
            </div>
            <div>
              <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                School / University{" "}
              </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {selectedQualification?.school}
              </h5>
            </div>
            <div>
              <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                Completion Year
              </h5>
              <h5
                style={{
                  fontSize: "95%",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                {selectedQualification?.endDate?.substring(0, 4)}
              </h5>
            </div>
          </div>
          <div style={{ marginLeft: "100px" }}>
            <h5>CERTIFICATE</h5>
            <div className="con">
              <div className="overlay">
                {/* <p style={{ fontSize: "0.833vw", fontWeight: "700" }}>
                  myCertificate.pdf
                </p> */}
                <a href={selectedQualification?.document} target="_blank">
                  <p
                    style={{
                      fontSize: "0.729vw",
                      color: "#FFCB95",
                      fontWeight: "400",
                    }}
                  >
                    (Click to review)
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            float: "right",
            paddingTop: "8px",
            marginLeft: "77%",
            marginBottom: "0.521vw",
          }}
        >
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#F5365C",
              borderColor: "#F5365C",
            }}
            onClick={() => {
              deleteQualification(selectedQualification._id);
              setOpen1(false);
            }}
            type="button"
          >
            Remove
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen2}
        toggle={() => setOpen2(!isOpen2)}
      >
        <div style={{ marginTop: "1.302vw", textAlign: "center" }}>
          <h1>Add Education </h1>
          <p style={{ color: "#9A9A9A" }}>
            {" "}
            Add qualification details and upload a certificate
          </p>
        </div>
        <div style={{ margin: "0.521vw" }} className="modal-body">
          <h5>School</h5>
        </div>
        <div
          style={{
            marginTop: "-35px",
            marginLeft: "35px",
            marginRight: "0.521vw",
            width: "180%",
            height: "50%",
          }}
        >
          <FormGroup style={{ width: "45%" }} className="mb-0">
            <Input
              onChange={(e) => setSchool(e.target.value)}
              type="text"
              placeholder=""
            />
          </FormGroup>
        </div>
        <div style={{ margin: "0.521vw" }} className="modal-body">
          <h5>Degree</h5>
        </div>
        <div
          style={{
            marginTop: "-35px",
            marginLeft: "35px",
            marginRight: "0.521vw",
            width: "180%",
            height: "50%",
          }}
        >
          <FormGroup style={{ width: "45%" }} className="mb-0">
            <Input
              onChange={(e) => setDegree(e.target.value)}
              type="text"
              placeholder=""
            />
          </FormGroup>
        </div>
        <div style={{ margin: "0.521vw" }} className="modal-body">
          <h5>Area of study</h5>
        </div>
        <div
          style={{
            marginTop: "-35px",
            marginLeft: "35px",
            marginRight: "0.521vw",
            width: "180%",
            height: "50%",
          }}
        >
          <FormGroup style={{ width: "45%" }} className="mb-0">
            <Input
              onChange={(e) => setAreaOfStudy(e.target.value)}
              type="text"
              placeholder=""
            />
          </FormGroup>
        </div>
        <div
          style={{
            padding: "1.042vw",
            marginLeft: "15px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <h5>Completion date</h5>
            <FormGroup
              style={{ width: "120%", paddingRight: "50px" }}
              className="mb-0"
            >
              <Input
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                placeholder=""
                style={{ width: "160px" }}
              />
            </FormGroup>
          </div>
          <div style={{ marginLeft: "-1.563vw" }}>
            <h5>Upload Certificate</h5>
            <FormGroup
              style={{ width: "160%", paddingRight: "2px" }}
              className="mb-0"
            >
              <Input
                onChange={(e) => setDocument(e.target.value)}
                type="text"
                value={document}
                disabled
                placeholder=""
                style={{ width: "170px" }}
              />
            </FormGroup>
          </div>
          <div>
            <input
              hidden
              type="file"
              id="certificate"
              ref={certificateRef}
              onChange={handleCertifcateFile}
              accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF"
            />

            <Button
              component="label"
              onClick={() => {
                certificateRef.current?.click();
              }}
              style={{
                marginTop: "27px",
                height: "60%",
                border: "1px solid grey",
                backgroundColor: "#FFFBF6",
                borderRadius: "5px",
              }}
            >
              <i
                style={{ color: "#FF6700" }}
                className="fas fa-cloud-upload-alt"
              ></i>
            </Button>
          </div>
        </div>
        <div className="modal-footer">
          <p style={{ margin: "auto", color: "red" }}>{errMsg}</p>
          <Button
            style={{
              backgroundColor: "#FF6700",
              border: "1px solid #FF6700",
              color: "#fff",
              width: "100%",
            }}
            disabled={state.isLoading}
            onClick={() => addEducation()}
            type="button"
          >
            Add Education{" "}
            {state.isLoading && <Spinner style={{ width: 20, height: 20 }} />}
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen3}
        size="lg"
        style={{ maxWidth: "1000px", height: "80%", width: "100%" }}
        toggle={() => setOpen3(!isOpen3)}
      >
        <Schedule
          schedule={schedule}
          editable
          closeCalendar={() => closeCalendar()}
        />
      </Modal>
    </div>
  );
};

export default Tables;
