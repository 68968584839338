import { createContext, useEffect, useContext, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from ".../../views/pages/Chat/firebase-config";
import { BASE_URL } from "services/Path";

export const InBoxServiceContext = createContext({
  data: [],
  isLoading: true,
  getAdminDisplay: () => {},
});

const InBoxServiceProvider = ({ children }) => {
  const { adminId, chatType } = children.props;
  // const {loading}=props
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  let folderName = BASE_URL?.includes("dev")
    ? "Dev-Support-Conversations"
    : "Live-Support-Conversations";

  const userCollectionRef = collection(db, folderName);

  const getAdminDisplay = () => {
    setisLoading(true);

    let q;
    if (chatType == "allChat") {
      q = query(userCollectionRef, orderBy("timestamp", "desc"));
    } else {
      q = query(
        collection(db, folderName),
        where("adminList", "array-contains", adminId),
        orderBy("timestamp", "desc")
      );
    }

    const adminSnapShot = onSnapshot(q, (snapshot) => {
      const data1 = snapshot.docs.map((doc, index) => {
        const entry = doc.data();
        return {
          adminList: entry.adminList,
          adminNameList: entry.adminNameList,
          appUser: entry.appUser,
          appUserName: entry.appUserName,
          appUserPhotoUrl: entry.appUserPhotoUrl,
          appUserType: entry.appUserType,
          id: entry.id,
          unreadCountAppUser: entry.unreadCountAppUser,
          unreadCountAdmin: entry.unreadCountAdmin,
          lastMsg: entry.lastMsg,
          lastSenderId: entry.lastSenderId,
          timestamp: entry.timestamp,
        };
      });
      // loading(false)
      setisLoading(false);
      setData(data1);
    });

    return adminSnapShot;
  };

  useEffect(() => {
    setisLoading(true);
    const adminSnapShot = getAdminDisplay();
    setisLoading(false);
    // loading(false)
    // const messagesSnapShot = getMessageDisplay();
    // console.log(data, 'data fromInBoxFBHook')
    return () => {
      adminSnapShot();
      // messagesSnapShot()
    };
  }, []);

  return (
    <InBoxServiceContext.Provider
      value={{
        data,
        isLoading,
        // getMessageDisplay,
        getAdminDisplay,
      }}
    >
      {children}
    </InBoxServiceContext.Provider>
  );
};

export const adminService = () => useContext(InBoxServiceContext);

export default InBoxServiceProvider;
// const getMessageDisplay = () => {
//     let queryArray=[];
//     console.log(data,'data2222 inside getMessageDisplay')
//     data?.map((docId, i=0)=>{
//         console.log(docId.id,'docId')
//         queryArray[i] = query(collection(db, "Support_Conversations", docId.id, docId.id), orderBy('timestamp', "desc"), limit(1));
//     })
//     console.log(queryArray,'queryArray')
//     const q = query(collection(db, "Support_Conversations", "62b4c81f8268fb3fe42f3b4c-62b79248ab41270018e92854", "62b4c81f8268fb3fe42f3b4c-62b79248ab41270018e92854"), orderBy('timestamp', "desc"), limit(1));

//     const messagesSnapShot = onSnapshot(queryArray[0], (snapshot) => {
//         const message = snapshot.docs.map((doc, index) => {
//             const entry = doc.data();
//             console.log(entry, index, 'enter InBoxHook')

//             return {
//                 content: entry.content,
//                 fromName:entry.fromName,
//                 idFrom: entry.idFrom,
//                 idTo: entry.idTo,
//                 type: entry.type,
//                 timestamp: entry.timestamp
//             };

//         });

//         setMessages(message);
//     });

//     return messagesSnapShot;
// };
// const getMessage = useCallback(async (content, fromName,idFrom, idTo) => {

//     let timestamp= new Date().getTime();
//     const messageData = {
//         content,
//         fromName,
//         idFrom,
//         idTo,
//         type: 0,
//         timestamp:`${timestamp}`
// };
//     try {
//         await setDoc(doc(db, "Support_Conversations", chatId, chatId) , messageData );
//     } catch (e) {
//         console.log(e);
//     }
// }, []);
