/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import api from "../services/api";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  // Progress,
  // Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { isEmpty } from "lodash";
import RevenueGraph from "components/RevenueGraph";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [graphType, setGraphType] = useState("month");
  const [dashboardStats, setDashboardStats] = useState({});
  const [billing, setBilling] = useState([]);
  const [tutorPayableSalaries, setTutorPayableSalaries] = useState([]);
  const [revenueData, setRevenueData] = useState(null);

  const [state, setState] = useState({
    graphMonths: [],
    graphData: [],
    allData: [],
  });

  const [stats, setStats] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Tuition Stats",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 10,
      },
    ],
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  async function getStats() {
    try {
      const stats = await api.getTuitionStats();
      setStats(stats.data);
    } catch (e) {}
  }
  async function getDashboardStats() {
    try {
      const dashboardStats = await api.getDashboardStats();
      setDashboardStats(dashboardStats.data);
    } catch (e) {}
  }

  function filterInvoicesByAllMonths2(dataArray) {
    let today = new Date();
    let last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);

    let finalArray = [];

    const monthNames = {
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sept",
      October: "Oct",
      November: "Nov",
      December: "Dec",
    };

    let final = dataArray.reduce((filteredData, item) => {
      item?.job[0]?.jobStatus !== 1 &&
        item.invoices.forEach((invoice) => {
          const invoiceDate = new Date(invoice.month);

          if (invoiceDate >= last6Months && invoiceDate <= today) {
            const shortMonth = monthNames[invoice.month.split(",")[0].trim()];
            if (!filteredData[shortMonth]) {
              filteredData[shortMonth] = [];
            }
            filteredData[shortMonth].push(invoice);
          }
        });

      return filteredData;
    }, {});

    // Sort the months in chronological order
    const sortedMonths = Object.keys(final).sort(
      (a, b) => new Date(a + " 1, 2000") - new Date(b + " 1, 2000")
    );

    sortedMonths.forEach((shortMonth) => {
      const value = final[shortMonth];
      let totalSalary = 0;
      let totalBill = 0;
      let paid = 0;

      value.forEach((record) => {
        totalSalary += record.salary;
        totalBill += record.amount;
        paid += record.amount - record.remainingAmount;
      });

      finalArray.push({
        month: shortMonth,
        totalSalary,
        totalBill,
        paid,
        netRevenue: totalBill - totalSalary,
      });
    });

    const graphData = finalArray.map((item) => item.netRevenue);
    const graphMonths = finalArray.map((item) => item.month);

    setState((prev) => ({
      ...prev,
      graphMonths,
      graphData,
      allData: finalArray,
    }));

    prepareReveneGraphData(graphMonths, graphData);
  }

  const calculatePayableSalariesOfTuturs = (billing) => {
    const formattedData = {};
    const selectedMonth = moment(new Date()).format("MMMM, YYYY");

    billing?.result?.forEach((invoicesList) => {
      if (invoicesList.job[0].jobStatus !== 1 && !isEmpty(invoicesList.tutor)) {
        const tutorName = invoicesList?.tutor[0]?.name || "N/A";

        // Check if the tutor name is not already in the formattedData
        if (!formattedData[tutorName]) {
          formattedData[tutorName] = {
            bankInfo: !isEmpty(invoicesList?.tutorBank)
              ? `${invoicesList?.tutorBank[0]?.bankName}, ${invoicesList?.tutorBank[0]?.accountNumber}`
              : "N/A",
            tutionClasses: [],
            sumOfAllTutionClassesSalary: 0,
          };
        }

        invoicesList?.invoices
          .filter((invoice) => invoice.month === selectedMonth)
          .forEach((invoice) => {
            const tuitionName = invoicesList.job[0]?.courseName || "N/A";
            const invoiceNumber = `#${invoice.invoice} (${invoice.type})`;
            const tuteeName = `${invoicesList?.tutee[0]?.name || "N/A"} (${
              invoicesList?.tutee[0]?.email || ""
            })`;
            const totalBill = `$${invoice.amount || 0}`;
            const tutorSalary = `$${invoice.salary || 0}`;

            const tutionClassData = {
              tutionName: tuitionName,
              invoiceNumber: invoiceNumber,
              tuteeName: tuteeName,
              totalBill: totalBill,
              tutorSalary: tutorSalary,
            };

            // Add the tutionClassData to the tutor's tutionClasses
            formattedData[tutorName].tutionClasses.push(tutionClassData);

            // Update the sum of all tutor salaries
            formattedData[tutorName].sumOfAllTutionClassesSalary =
              parseFloat(formattedData[tutorName].sumOfAllTutionClassesSalary) +
              parseFloat(tutorSalary.slice(1));
          });
      }
    });

    let total = 0;

    Object.values(formattedData).map((item) => {
      total += parseFloat(item.sumOfAllTutionClassesSalary);
    });

    return total;
  };

  const getBillingInfo = async () => {
    try {
      const billing = await api.getBilling();
      let salaries = calculatePayableSalariesOfTuturs(billing.data);
      setTutorPayableSalaries(salaries);
      filterInvoicesByAllMonths2(billing?.data?.result);
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    getStats();
    getDashboardStats();
    getBillingInfo();
  }, []);

  return (
    <div style={{ backgroundColor: "#FFF8F0" }}>
      <Header
        cards
        dashboardStats={dashboardStats}
        tutorPayableSalaries={tutorPayableSalaries}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card
              className="bg-gradient-default shadow"
              style={{ minHeight: "300px", height: "100%" }}
            >
              <CardHeader className="bg-transparent" style={{ margin: 0 }}>
                <Row className="align-items-center" style={{ width: "100%" }}>
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <h2 className="text-white mb-0">Net Revenue</h2>
                      <p data-tip="Net Revenue =  Total Revenue - Total Salaries">
                        <i
                          className="fas fa-info-circle icon-cog"
                          style={{
                            color: "white",
                            paddingLeft: "20px",
                            marginTop: "6px",
                            fontSize: "120%",
                          }}
                        ></i>
                      </p>
                      <ReactTooltip />
                    </div>
                  </div>

                  {/* <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          style={
                            activeNav === 1
                              ? { backgroundColor: "#ff6700" }
                              : null
                          }
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => {
                            toggleNavs(e, 1);
                            setGraphType("month");
                          }}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={
                            activeNav === 2
                              ? { backgroundColor: "#ff6700" }
                              : null
                          }
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => {
                            toggleNavs(e, 2);
                            setGraphType("week");
                          }}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div> */}
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div
                  className="chart"
                  style={{ minHeight: "300px", height: "100%" }}
                >
                  <RevenueGraph
                    data={state.graphData}
                    labels={state.graphMonths}
                    allData={state.allData}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card
              className="shadow"
              style={{ minHeight: "300px", height: "100%" }}
            >
              <CardHeader className="bg-transparent" style={{ margin: 0 }}>
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      OVERVIEW
                    </h6>
                    <h2 className="mb-0">Tuitions started</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div
                  className="chart"
                  style={{ minHeight: "300px", height: "100%" }}
                >
                  <Bar data={stats} options={chartExample2.options} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
