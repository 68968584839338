/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  // InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import SupportModal from "components/Modal/SupportModal";
import Avatar from "@mui/material/Avatar";
import ForumIcon from "@mui/icons-material/Forum";

import "./index.css";
import { alpha } from "@mui/material";





const AdminNavbar = (props) => {
  const [loggedUser, setUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setUser(user);
  }, []);
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <hr
          style={{
            height: "2px",
            borderWidth: 0,
            color: "gray",
            backgroundColor: "gray",
          }}
        />

        <Container fluid>
          {props.brandText === "Dashboard" ? (
            <Link
              style={{
                borderRadius: "8px",
                padding: "5px",
                color: "#fff",
                textTransform: "uppercase",
              }}
              to={props}
            >
              <b>{`${props.brandText}`}</b>
            </Link>
          ) : (
            <Link
              style={{
                borderRadius: "6px",
                padding: "9px 15px",
                fontSize: "16px",
                color: "#32325D",
                fontFamily: "open sans",
                fontWeight: 400,
                lineHeight: "22px",
              }}
              className="breadCrumb"
              to={props}
            >
              <i
                style={{ color: "#FF6700", marginLeft: "5px" }}
                className="fas fa-home"
              ></i>{" "}
              {`/ ${props.brandText}`}
            </Link>
          )}

          {/* <hr ></hr> */}

          {/* <Media style={{marginBottom:"20px"}} className="ml-auto d-none d-lg-block">
            <span className="mb-0 text-sm font-weight-bold">
               <i className="fas fa-bell"></i>
            </span>
          </Media> */}
          <Nav
            style={{ display: "flex", flexDirection: "column" }}
            className="align-items-center d-none d-md-flex"
            navbar
          >
            <UncontrolledDropdown nav>
              <div className="pr-0 navbar-headerSupport" nav>
                {/* <i className="ni ni-support-16 adminNav-supportBox" onClick={() => setOpenModal(true)} /> */}

                <ForumIcon
                  style={{ color: "#fff", marginRight: 30, cursor: "pointer" }}
                  onClick={() => setOpenModal(true)}
                />

                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle"> */}
                  {/* <img
                      style={{ width: 50, height: 50 , marginRight:16 }}
                      alt="..."
                      src={
                        // C:\Users\DEENS CDS\work\work\adminPanel\src\assets\img\theme\team-1-800x800.jpg
                        require("../../assets/img/theme/team-1-800x800.jpg")
                          .default
                      }
                    /> */}

                  <Avatar
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      backgroundColor: "#ced4da",
                    }}
                    alt={loggedUser?.name}
                    src="/"
                  />
                  {/* </span> */}
                  <Media className="ml-2 d-none d-lg-block">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: 56,
                      }}
                    >
                      <span
                        style={{
                          width: "120px",
                          fontFamily: "open sans",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#FFFFFF",
                        }}
                      >
                        {loggedUser?.name}
                      </span>

                      {/* <Chip label={loggedUser?.adminType} style={ {fontSize:11 , borderRadius:6, backgroundColor:"#ececec" }}  size="small" variant="filled"/>
                       */}

                      <div
                        style={{
                          fontSize: "12px",
                          color: "#ffffff",
                          fontWeight: "500",
                          backgroundColor: alpha("#e9ecef", 0.2),
                          height: "20px",
                          width: "fit-content",
                          textAlign: "center",
                          borderRadius: "6px",
                          padding: "0px 8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {loggedUser?.adminType}
                      </div>
                    </div>
                  </Media>
                </Media>
              </div>
              {/* <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link} onClick={() => setOpenModal(true)}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu> */}
            </UncontrolledDropdown>

            {/* <div style={{marginTop:"30px",width:"350px" ,borderColor:"#fff"}} className="mb-0">
                <input style={{background:"transparent", color:"#fff", border: '3px solid #fff', borderRadius: '50%', width: '300px', height: '50px'}} placeholder="Search by tutee id, name, phone or email" type="text"  />
            </div> */}
            {/* <FormGroup style={{marginTop:"30px",width:"350px" ,borderColor:"#fff"}} className="mb-0">
                    <InputGroup >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{background:"transparent",color:"#fff",borderColor:"#fff"}}>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input style={{background:"transparent",borderColor:"#fff",color:"#fff"}} placeholder="Search by tutee id, name, phone or email" type="text" />
                    </InputGroup>
                  </FormGroup> */}
          </Nav>
        </Container>
      </Navbar>
      {openModal && <SupportModal adminId={loggedUser?._id} loggedUser={loggedUser} closeModal={() => setOpenModal()} />}
    </>
  );
};

export default AdminNavbar;
