import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAJiye5NBm2wgGJWZiy_fecQEZJMAyeAoQ",
  authDomain: "megamind-b9b91.firebaseapp.com",
  projectId: "megamind-b9b91",
  storageBucket: "megamind-b9b91.appspot.com",
  messagingSenderId: "395954232792",
  appId: "1:395954232792:web:bdf112646e08a4a37066ad",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const firebaseStorage = getStorage(app);
