/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

const StatsCard = ({ title, total, icon, perc, percText, percIcon, small }) => {
  return (
    <Card
      className="card-stats mb-4 mb-xl-0"
      style={{
        height: "87%",
        borderRadius: "12px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      <CardBody style={{ padding: 24 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "left",
            lineHeight: "1.5",
          }}
        >
          <div>
            <CardTitle
              style={{ fontSize: window.innerWidth <= 1444 ? 11 : 14 }}
              tag="h4"
              className="text-uppercase text-muted mb-0"
            >
              {title}
            </CardTitle>
          </div>
          <div>
            <span style={{ fontSize: 30, fontWeight: 700, color: "#333333" }}>
              {total}
            </span>
          </div>
          <div>
            <p style={{ fontSize: window.innerWidth <= 1444 ? 12 : 14 }}>
              <span
                className="text-success mr-2"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {percIcon && (
                  <i
                    className={percIcon}
                    style={{ marginTop: "4px", marginRight: "4px" }}
                  />
                )}
                <b>{perc}</b>
                <span
                  className="text-nowrap"
                  style={{ color: "grey", marginLeft: "5px", fontSize: "90%" }}
                >
                  {percText}
                </span>
              </span>
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default StatsCard;
