/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Leads from "views/pages/Lead/leads.js";
import Users from "views/pages/Users/Users.js";
import jobPosts from "views/pages/JobPost/jobPost.js";
import Courses from "views/pages/Courses/Courses.js";
import Biling from "views/pages/Biling/Biling.js";
import pushNotification from "views/pages/PushNotification/pushNotification.js";
import activityLog from "views/pages/ActivityLog/activityLog.js";
import Setting from "views/pages/Setting.js";
import logOut from "views/pages/logOut.js";
import Tutor from "views/pages/Tutor.js";
import tuteeUser from "views/pages/tuteeUser.js";
import adminUser from "views/pages/adminUser.js";
import Tutee from "views/pages/Tutee.js";
import Member from "views/pages//Member/Member.js";
import VerifyEmail from "views/pages/verifyEmail.js";

// import Profile from "views/pages/Profile.js";
// import Maps from "views/pages/Maps.js";
import chatlog from "views/pages/chatlog.js";
import Login from "views/pages/Login.js";
import vouchers from "views/pages/Vouchers/vouchers.js";
import TutionDetails from "views/pages/TutionDetails.js";
// import Mohsin from "views/pages/Mohsin.js";
import Pay from "views/pages/Pay.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-desktop",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "fas fa-link",
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "fas fa-user-friends",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/jobPosts",
    name: "Job Posts",
    icon: "fas fa-briefcase",
    component: jobPosts,
    layout: "/admin",
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "far fa-file-alt",
    component: Courses,
    layout: "/admin",
  },
  {
    path: "/biling",
    name: "Billing",
    icon: "fas fa-dollar-sign",
    component: Biling,
    layout: "/admin",
  },
  {
    path: "/pushNotification",
    name: "Push Notification",
    icon: "far fa-bell",
    component: pushNotification,
    layout: "/admin",
  },
  {
    path: "/activityLog",
    name: "Activity Log",
    icon: "fas fa-list-ul",
    component: activityLog,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: "ni ni-bullet-list-67 text-red",
    component: vouchers,
    layout: "/admin",
  },
  {
    path: "/Member",
    name: "Memberships",
    icon: "ni ni-bullet-list-67 text-red",
    component: Member,
    layout: "/admin",
  },
  {
    path: "/",
    name: "Logout",
    icon: "fas fa-sign-out-alt",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/Tutee/:id",
    name: "Tutee",
    icon: "fas fa-desktop",
    component: Tutee,
    layout: "/admin",
  },

  {
    path: "/Tutor/:id",
    name: "Tutor",
    icon: "fas fa-user-friends",
    component: Tutor,
    layout: "/admin",
  },

  {
    path: "/TutionDetails/:id",
    name: "Tution Details",
    icon: "ni ni-planet text-blue",
    component: TutionDetails,
    layout: "/admin",
  },

  // {
  //   path: "/pay",
  //   name: "\Pay",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Pay,
  //   layout: "/admin",
  // },
  // {
  //   path: "/chatlog",
  //   name: "Chat Log",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: chatlog,
  //   layout: "/admin",
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth",
  // },
  // {
  //   path: "/setting",
  //   name: "Settings",
  //   icon: "fas fa-cog",
  //   component: Setting,
  //   layout: "/admin",
  // },
  // {
  //   path: "/adminUser",
  //   name: "Admin Users",
  //   icon: "fas fa-user-friends",
  //   component: adminUser,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },

  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
  // {
  //   path: "/mohsin",
  //   name: "Mohsin",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Mohsin,
  //   layout: "/admin",
  // },
];
export default routes;
