import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Row,
  UncontrolledTooltip,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import rs from "../../assets/img/icons-white/reschedule.png";
import eye from "../../assets/img/icons-white/eye.png";
import dollar from "../../assets/img/icons-white/dollar.png";
import add from "../../assets/img/icons-white/add.png";
import chat from "../../assets/img/icons-white/chat.png";

function convertDate(date) {
  var today = new Date(date);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    date.substring(11, 16) +
    " - " +
    today.getDate() +
    " " +
    monthNames[today.getMonth()] +
    ", " +
    today.getFullYear()
  );
}

const ActivityLogProfile = ({activitylogs}) => (
  <div>
    <div>
      <div className="border-0" style={{margin:"0px", padding:"1.250vw 1.563vw", height:"3.802vw"}} >
        <h3 style={{ height:"22.448vw", fontSize:"0.938vw",color:"#454545",fontWeight:600, }} className="mb-0">
          Admin Activity Log
        </h3>
      </div>
    </div>
    <hr style={{ marginTop: "0px",marginBottom:"0px",margin:"0px 0px 1.042vw 0px" }}></hr>
    <div className="PrimaryScrollQualification11">
      {activitylogs.length ? (
        activitylogs.map((activitylog, index) =>
          activitylog.activityType === "Reschedule" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#89C440" }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={rs} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw"
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    Rescheduled class of tuition{" "}
                  </span>
                  <Link
                    to={"/admin/TutionDetails/" + activitylog.job[0]._id}
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog.job[0].courseName}{" "}
                  </Link>
                  <span style={{ color: "#9A9A9A" }}> from</span>
                  <span
                    style={{
                      color: "#454545",
                      fontSize: "0.729vw",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {activitylog.message}
                  </span>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "Chat Log" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#00BCD6" }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={eye} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    Viewed Tutor-Tutee chat log for tuition
                  </span>
                  <Link
                    to={"/admin/TutionDetails/" + activitylog?.job[0]?._id}
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog?.job[0]?.courseName}{" "}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "View Profile" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#00BCD6" }}
                  className="avatar rounded-circle mr-20"
                  
                >
                  <img src={eye} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    {`Viewed profile of ${activitylog.message}`}
                  </span>
                  <Link
                    to={
                      activitylog.message === "tutor"
                        ? "/admin/Tutor/" + activitylog?.user[0]?._id
                        : "/admin/Tutee/" + activitylog?.user[0]?._id
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog?.user[0]?.name}{" "}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "Update Education" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#89C440" }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={rs} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    Updated education details of tutor
                  </span>
                  <Link
                    to={
                      activitylog.message === "Tutor"
                        ? "/admin/Tutor/" + activitylog?.user[0]?._id
                        : "/admin/Tutee/" + activitylog?.user[0]?._id
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog?.user[0]?.name}{" "}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "View Tuition" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#00BCD6" }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={rs} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    Viewed details of tuition
                  </span>
                  <Link
                    to={"/admin/TutionDetails/" + activitylog?.job[0]?._id}
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog?.job[0]?.courseName}{" "}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "Send Message" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#EC1561"  }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={chat} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    {`Send Message as support to ${activitylog.message} `}
                  </span>
                  <Link
                    to={
                      activitylog.message === "Tutor"
                        ? "/admin/Tutor/" + activitylog?.user[0]?._id
                        : "/admin/Tutee/" + activitylog?.user[0]?._id
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog?.user[0]?.name}{" "}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "Change Salary Status" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#FF9800" }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={dollar} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    {`Changed ${activitylog.message1} salary status of tutor `}
                  </span>
                  <Link
                    to={
                      activitylog.message === "Tutor"
                        ? "/admin/Tutor/" + activitylog?.user[0]?._id
                        : "/admin/Tutee/" + activitylog?.user[0]?._id
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    {" "}
                    {activitylog?.user[0]?.name}{" "}
                  </Link>
                  <span style={{ color: "#9A9A9A" }}>
                    {` to ${activitylog.message2} `}
                  </span>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : activitylog.activityType === "Add New Course" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "1.042vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <div
                  style={{  fontSize: "2.500vw",backgroundColor: "#00A7F7" }}
                  className="avatar rounded-circle mr-20"
                >
                  <img src={add} style={{ width: "1.250vw" }} />
                </div>
                {index !== activitylogs.length - 1 ? (
                  <div
                    style={{
                      borderLeft: "1px solid #d3d3d3",
                      height: "1.563vw",
                      
                    }}
                  ></div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "10px", fontSize: "0.729vw" }}>
                  <span style={{ color: "#9A9A9A" }}>
                    {`Added new course ${activitylog.message} with level ${activitylog.message1}`}
                  </span>
                </div>
                <div style={{ marginBottom: "1.042vw" }}>
                  <span
                    style={{
                      color: "#9A9A9A",
                      fontSize: "0.625vw",
                      marginLeft: "10px",
                    }}
                  >
                    {convertDate(activitylog.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ) : null
        )
      ) : (
        <p style={{ textAlign: "center" }}>No Activity Logs</p>
      )}
    </div>
  </div>
);

export default ActivityLogProfile;
