import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Row,
  UncontrolledTooltip,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Col,
} from "reactstrap";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };


  render() {
    return (
      // Important! Always set the container height explicitly
      // <div style={{ height: "40vh", width: "100%" }}>
      //   <CardHeader
      //     className=" text-center border-0"
      //     style={{
      //       display: "flex",
      //       flexDirection: "row",
      //       justifyContent: "space-between",
      //       alignItems: "center",
      //     }}
      //   >
      //     <div
      //       style={{
      //         display: "flex",
      //         flexDirection: "column",
      //         justifyContent: "flex-start",
      //         alignItems: "left",
      //         textAlign: 'left'
      //       }}>
      //       <h3>
      //         Location1q1
      //       </h3>
      //       <h5>
      //         <i className="fa fa-map-marker"></i> &nbsp;
      //         {this.props?.address}
      //       </h5>
      //     </div>

      //     <Link>
      //       <div className="supportButton3"> <i className="fas fa-pen"></i>{" "}Edit</div>
      //     </Link>
      //   </CardHeader>
      //   <CardBody>
      //     <GoogleMapReact
      //       bootstrapURLKeys={{ key: "" }}
      //       defaultCenter={this.props.center}
      //       defaultZoom={this.props.zoom}
      //     >
      //       <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
      //     </GoogleMapReact>
      //   </CardBody>
      // </div>
      <div></div>
    );
  }
}

export default SimpleMap;
