import { InputLabel, Select } from "@mui/material";
import Dropdown from "components/Dropdown";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import React from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";

const UserOptions = [
  {
    label: "All users",
    value: "ALL_USER",
  },
  {
    label: "All tutee",
    value: "ALL_TUTEE",
  },
  {
    label: "All tutor",
    value: "ALL_TUTOR",
  },
  // {
  //   label: "Custom Tutee user",
  //   value: "CUSTOM_TUTEE",
  // },
  // {
  //   label: "Custom Tutor user",
  //   value: "CUSTOM_TUTOR",
  // },
];

const SendNotificationModal = ({
  isOpen,
  allTutees,
  allTutors,
  onSelect,
  selectedOption,
  showMultiselect,
  setOpen,
  setTitle,
  setMessage,
  sendNotification,
  handleDropdownChange,
}) => {
  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => setOpen(!isOpen)}
      >
        <div style={{ padding: "30px" }}>
          <div style={{ marginTop: "30px", textAlign: "center" }}>
            <h1>Send push notification</h1>
            <p style={{ color: "#9A9A9A" }}> Enter message and choose option</p>
          </div>
          <div
            style={{
              marginTop: "-10px",
              height: "50%",
            }}
          >
            <h5 style={{ marginRight: "22px" }}>Title</h5>
            <FormGroup style={{ height: "50px" }} className="mb-0">
              <Input
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                style={{ wordWrap: "break-word", height: "50px" }}
              />
            </FormGroup>
          </div>
          <div style={{ margin: "10px 0px" }}>
            <h5 style={{ marginRight: "22px" }}>Body</h5>
          </div>
          <div
            style={{
              // marginTop: "-25px",
              // marginLeft: "40px",
              marginBottom: "10px",
              // width: "180%",
              height: "50%",
            }}
          >
            <FormGroup style={{ height: "50%" }} className="mb-0">
              <Input
                onChange={(e) => setMessage(e.target.value)}
                type="textarea"
                style={{ wordWrap: "break-word", height: "100px" }}
              />
            </FormGroup>
          </div>
          <div style={{ margin: "15px 0px" }}>
            <h5>Send to</h5>
          </div>
          <div>
            <Dropdown
              name="selectUser"
              value={selectedOption}
              options={UserOptions}
              onChange={handleDropdownChange}
            />

            {showMultiselect && (
              <div style={{ margin: "15px 0px" }}>
                <h5>
                  {selectedOption === "CUSTOM_TUTOR"
                    ? "Tutor List"
                    : "Tutee List"}
                </h5>

                <MultiSelectDropdown
                  title="Add User"
                  options={
                    selectedOption === "CUSTOM_TUTOR" ? allTutors : allTutees
                  }
                  onSelect={onSelect}
                />

                {/* <FormControl sx={{ m: 1, width: "400px" }}>
                  <InputLabel id="demo-multiple-chip-label">Select</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    onChange={onSelect}
                    value={selectedValues.map((v) => v.name)}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Subjects"
                      />
                    }
                    renderValue={() => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {selectedValues.map((value) => (
                          <Chip
                            style={{ fontSize: "0.625vw" }}
                            key={value.id}
                            label={value.name}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {selectedOption === "CUSTOM_TUTOR"
                      ? allTutors.map((val) => (
                          <MenuItem
                            key={val.id}
                            value={val}
                            // style={getStyles(
                            //   val.name,
                            //   personName,
                            //   theme
                            // )}
                          >
                            {val.name}
                          </MenuItem>
                        ))
                      : allTutees.map((val) => (
                          <MenuItem
                            key={val.id}
                            value={val}
                            // style={getStyles(
                            //   val.name,
                            //   personName,
                            //   theme
                            // )}
                          >
                            {val.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl> */}
              </div>
            )}
          </div>

          <div className="modal-footer">
            <Button
              style={{
                backgroundColor: "#FF6700",
                border: "1px solid #FF6700",
                color: "#fff",
                width: "100%",
                marginTop: "30px",
              }}
              type="button"
              onClick={() => sendNotification()}
            >
              Send Notification
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SendNotificationModal;
