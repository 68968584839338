import React from "react";
import { Modal } from "reactstrap";

import "./styles.css";
import { isEmpty } from "lodash";

const ReviewListModal = ({ isOpen, handleModal, reviewsList = [] }) => {
  if (isEmpty(reviewsList)) {
    return <></>;
  }

  return (
    <div>
      <Modal
        className="modal-dialog-centered BillingInvoiceModal2"
        toggle={(e) => {
          handleModal();
        }}
        isOpen={isOpen}
      >
        <div className="reviewContainer">
          <div className="reviewHeader">
            <h2 className="reviewsTitle">Reviews</h2>

            <i
              style={{
                marginLeft: "270px",
                paddingTop: "3px",
                color: "#9A9A9A",
              }}
              onClick={() => handleModal()}
              className="fas fa-times"
            ></i>
          </div>

          <div>
            {reviewsList.map((review) => (
              <div className="reviewBlock">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    Rating :&nbsp;
                    <i className="fa fa-star" style={{ color: "#ffd700" }}></i>
                    &nbsp;
                    {review.rating || 0}
                  </div>
                </div>
                <div>
                  Feedback :{" "}
                  <span className="reviewText">{review.feedback}</span>
                </div>

                <div className="line-1 ">
                  <div className="reviewerName">{review.tuteeName}</div>
                  <div className="reviewClass">{review.jobTitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReviewListModal;
