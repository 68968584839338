import {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase-config";
import api from "../../../services/api";
import { BASE_URL } from "services/Path";

export const MessageServiceContext = createContext({
  messages: [],
  isLoading: true,
  addMessage: () => {},
});

const MessageServiceProvider = ({ children }) => {
  const { chatId, chatInfo } = children.props;
  const [messages, setMessages] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  let folderName = BASE_URL?.includes("dev")
    ? "Dev-Support-Conversations"
    : "Live-Support-Conversations";

  console.log(chatInfo, "chatInfochatInfo");

  const getMessagesForchat = () => {
    const q = query(
      collection(db, folderName, chatId, chatId),
      orderBy("timestamp", "asc")
    );

    const messagesSnapShot = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc, index) => {
        const entry = doc.data();
        return {
          content: entry.content,
          fromName: entry.fromName,
          idFrom: entry.idFrom,
          idTo: entry.idTo,
          type: entry.type,
          timestamp: entry.timestamp,
        };
      });

      setMessages(data);
      setisLoading(false);
    });

    return messagesSnapShot;
  };

  const addMessage = useCallback(async (content, fromName, idFrom, idTo) => {
    let updatedChatInfo;

    let q = query(collection(db, folderName), where("id", "==", chatInfo.id));

    const querySnapshot = await getDocs(q);

    // Iterate over the document snapshots
    querySnapshot.forEach((doc) => {
      // Access the document data
      updatedChatInfo = doc.data();
    });

    if (!content) {
      return;
    }
    let timestamp = new Date().getTime();
    const messageData = {
      content,
      fromName,
      idFrom,
      idTo,
      type: 0,
      timestamp: `${timestamp}`,
    };

    const adminData = {
      adminList: updatedChatInfo.adminList,
      adminNameList: updatedChatInfo.adminNameList,
      appUser: updatedChatInfo.appUser,
      appUserName: updatedChatInfo.appUserName,
      appUserPhotoUrl: updatedChatInfo.appUserPhotoUrl,
      appUserType: updatedChatInfo.appUserType,
      id: updatedChatInfo.id,
      //   ...chatInfo,
      unreadCountAppUser: updatedChatInfo.unreadCountAppUser + 1,
      unreadCountAdmin: 0,
      lastMsg: `${content}`,
      lastSenderId: updatedChatInfo.adminList[0],
      timestamp: `${timestamp}`,
    };

    console.log(adminData, "from messageFireBaseHook", updatedChatInfo);

    const notificationData = {
      userIds: [`${chatInfo.appUser}`],
      title: updatedChatInfo?.adminNameList[0],
      body: "New Message from Support",
      message: "New Message from Support",
      type: "New_Message",
      senderType:
        updatedChatInfo.appUserType === "Tutee"
          ? "CUSTOM_TUTEE"
          : "CUSTOM_TUTOR",
    };

    try {
      // console.log(adminData,'adminData')

      await setDoc(doc(db, folderName, chatId), adminData);
      await setDoc(
        doc(db, folderName, chatId, chatId, `${timestamp}`),
        messageData
      );
      await api.snedNotification(notificationData);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const markMessageAsRead = () => {
    const supportConversationObj = {
      adminList: chatInfo.adminList,
      adminNameList: chatInfo.adminNameList,
      appUser: chatInfo.appUser,
      appUserName: chatInfo.appUserName,
      appUserPhotoUrl: chatInfo.appUserPhotoUrl,
      appUserType: chatInfo.appUserType,
      id: chatInfo.id,
      unreadCountAppUser: chatInfo.unreadCountAppUser,
      unreadCountAdmin: 0,
      lastMsg: chatInfo.lastMsg,
      lastSenderId: chatInfo.lastSenderId,
      timestamp: chatInfo.timestamp,
    };

    setDoc(doc(db, folderName, chatId), supportConversationObj);
  };

  useEffect(() => {
    setisLoading(true);
    const messagesSnapShot = getMessagesForchat();
    setisLoading(false);

    markMessageAsRead();

    return () => {
      messagesSnapShot();
    };
  }, []);

  return (
    <MessageServiceContext.Provider
      value={{
        messages,
        isLoading,
        addMessage,
      }}
    >
      {children}
    </MessageServiceContext.Provider>
  );
};

export const messagesService = () => useContext(MessageServiceContext);

export default MessageServiceProvider;
