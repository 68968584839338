/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import * as React from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  Button,
  FormGroup,
  InputGroup,
  // InputGroupAddon,
  InputGroupText,
  Input,
  Col,
} from "reactstrap";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import ModalEdit from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import "./index.css";
import { async } from "@firebase/util";

import CreateVoucherModal from "./CreateVoucherModal";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { isEmpty } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Tables = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const notify = () => toast("Wow so easy!");
  const [tab, setTab] = useState(0);
  const [voucherName, setVoucherName] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");
  const [dropdown, setuser] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allTutees, setAllTutees] = useState([]);
  const [publishTo, setPublishTo] = useState("");
  const [selectedTutee, setSelectedTutee] = useState("");
  const [state, setState] = useState({
    isLoading: false,
  });

  const [editModal, setEditModal] = React.useState(false);
  const handleOpen = () => setEditModal(true);
  const handleClose = () => setEditModal(false);

  const handleSelectChange = (e) => {
    setPublishTo(e.target.value);
  };

  const handleTuteeChange = (e) => {
    setSelectedTutee(e.target.value);
  };

  const [publishToList, setPublishToList] = useState([]);
  const [err, setErr] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [voucherMembers, setVoucherMembers] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState([]);
  const [selectedVoucherIndex, setSelectedVoucherIndex] = useState(0);
  const [newMemberList, setNewMemberList] = useState([]);
  const [selectedNewMemberList, setSelectedNewMemberList] = useState([]);
  const [splitVoucherModel, setSplitVoucherModel] = useState(0);
  const [splitVoucherValueOne, setSplitVoucherValueOne] = useState({});
  const [splitVoucherValueTwo, setSplitVoucherValueTwo] = useState({});
  const [splitVoucherButtonDisabled, setSplitVoucherButtonDisabled] =
    useState(true);
  const [isGlobal, setIsGlobal] = useState(false);

  async function getAllTutee() {
    try {
      const allUsers = await api.getAllUsers(`?userType=Tutee`);
      // if (!allusers.length)
      const filteredData = allUsers.data.map((user, id) => {
        let temp = user;
        temp = { ...temp, id, name: `${user.name} (${user.email})` };
        return temp;
      });

      setAllTutees(filteredData);
      // }
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  async function getVouchers() {
    try {
      const vouchers = await api.getVouchers();

      setVouchers(vouchers.data);
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  function onSelect(selectedList, selectedItem) {
    let list = selectedList.map((s) => {
      const temp = {
        user: s,
        isRedeemed: false,
        joinDate: new Date(),
      };
      return temp;
    });
    setPublishToList(list);
  }

  function onSelect1(selectedList, selectedItem) {
    let list = selectedList.map((s) => {
      const temp = {
        user: s,
        isRedeemed: false,
        joinDate: new Date(),
      };
      return temp;
    });
    setSelectedNewMemberList(list);
  }

  const postVoucher = async () => {
    let list = [];
    let tutee = allTutees.filter((item) => item.name === selectedTutee)[0];
    if (publishTo === "Choose tutee") {
      list = [
        {
          user: {
            ...tutee,
            name: tutee.name.split("(")[0],
          },
          isRedeemed: false,
          joinDate: new Date(),
        },
      ];
    }

    if (voucherName) {
      if (price) {
        if (date) {
          if (publishToList.length || !isEmpty(selectedTutee)) {
            let updatedPublishList = publishToList.map((item) => ({
              ...item,
              user: {
                ...item.user,
                name: item.user?.name?.split("(")[0],
              },
            }));
            const data = {
              isGlobal: publishTo === "Is Global" ? true : false,
              voucherName,
              expiryDate: date,
              price,
              publishTo: list.length ? list : updatedPublishList,
            };
            const voucher = await api.postVoucher(data);

            setPublishTo([]);
            getVouchers();
            setOpen(false);
          } else {
            setErr("Select Users for voucher.");
          }
        } else {
          setErr("Please select date");
        }
      } else {
        setErr("Please enter price");
      }
    } else {
      setErr("Please enter voucher name");
    }
  };

  const convertDateFormat = (date) => {
    var today = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      today.getDate() +
      " " +
      monthNames[today.getMonth()] +
      ", " +
      today.getFullYear()
    );
  };

  const redeemCount = (v) => {
    let count = 0;
    v.forEach((element) => {
      if (element.isRedeemed) {
        count++;
      }
    });
    return count;
  };

  const makeNewmemberList = () => {
    const newMemberList = [];
    allTutees.forEach((tutee) => {
      let flag = false;
      voucherMembers.forEach((element) => {
        if (element.user._id === tutee._id) {
          flag = true;
        }
      });
      if (!flag) {
        newMemberList.push(tutee);
      }
    });
    setNewMemberList(newMemberList);
  };

  // const addNewMembers = async (action, i) => {
  //   if (action === "add") {
  //     const newList = [...voucherMembers, ...selectedNewMemberList];
  //     const voucher = await api.editVouchers(selectedVoucher._id, {
  //       publishTo: newList,
  //     });
  //     // const temp = vouchers;
  //     // temp[selectedVoucherIndex].publishTo =newList;
  //     // setVouchers(temp);
  //     setVoucherMembers(newList);
  //     setOpen1(false);
  //   } else {
  //     const lastList = voucherMembers;
  //     lastList.splice(i, 1);

  //     const voucher = await api.editVouchers(selectedVoucher._id, {
  //       publishTo: lastList,
  //     });
  //     setVoucherMembers(lastList);
  //     setOpen1(false);
  //   }
  // };
  const addNewMembers = async (action, i) => {
    try {
      if (action === "add") {
        let updatedSelectedMembers = selectedNewMemberList.map((item) => ({
          ...item,
          user: {
            ...item.user,
            name: item.user?.name?.split("(")[0],
          },
        }));
        const newList = [...voucherMembers, ...updatedSelectedMembers];
        await api.editVouchers(selectedVoucher._id, {
          publishTo: newList,
        });
        setVoucherMembers(newList);
        setOpen1(false);
        toast.success("Members added successfully!");
      } else {
        const lastList = voucherMembers.slice(); // Create a copy of the list to avoid mutating the original array
        lastList.splice(i, 1);

        await api.editVouchers(selectedVoucher._id, {
          publishTo: lastList,
        });
        setVoucherMembers(lastList);
        setOpen1(false);
        toast.success("Member removed successfully!");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      // Handle the error or display an error message to the user
    }
  };

  const handleSplitVoucher = async () => {
    console.log("handleSplitVoucher  CALLED");
    console.log({ selectedVoucher });
    setState((prev) => ({
      ...prev,
      isLoading: !prev.isLoading,
    }));

    const id = selectedVoucher?._id;
    const newVoucherData = {
      splitVoucherValueOne,
      splitVoucherValueTwo,
    };
    console.log({ newVoucherData });
    api
      .splitVoucher(id, newVoucherData)
      .then((response) => {
        console.log({ response });

        setState((prev) => ({
          ...prev,
          isLoading: !prev.isLoading,
        }));

        getVouchers();
        setSplitVoucherValueOne({});
        setSplitVoucherValueTwo({});
        setSplitVoucherModel(false);
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          isLoading: !prev.isLoading,
        }));
        toast.error("Error in splitting voucher");
      });
  };

  const handleSplitVoucherButtonDisabled = () => {
    const totalAmount = Number(
      Number(splitVoucherValueOne) + Number(splitVoucherValueTwo)
    );
    if (totalAmount === Number(selectedVoucher?.price)) {
      setSplitVoucherButtonDisabled(false);
    } else if (totalAmount !== Number(selectedVoucher?.price)) {
      setSplitVoucherButtonDisabled(true);
    }
  };

  const handleDeleteVoucher = (selectedVoucher) => {
    api
      .deleteVoucher(selectedVoucher._id)
      .then((resp) => {
        console.log({ resp });
        toast.success("Voucher Deleted Successfully");
        getVouchers();
      })
      .catch((error) => {
        console.log({ error });
        toast.success("Unable to delete voucher.");
      });
  };

  useEffect(() => {
    handleSplitVoucherButtonDisabled();
  }, [splitVoucherValueOne, splitVoucherValueTwo]);

  const handleSplitVoucherValueOne = (e) => {
    setSplitVoucherValueOne(Number(e.target.value));
  };
  const handleSplitVoucherValueTwo = (e) => {
    setSplitVoucherValueTwo(Number(e.target.value));
  };

  useEffect(async () => {
    getAllTutee();
    getVouchers();
  }, []);

  return (
    <>
      <Header />
      <Container
        fluid
        className={window.innerWidth <= 1366 ? "mt--7" : "mt--8"}
      >
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col xs={12} md={12} lg={5} xl={5} style={{ minWidth: "49%" }}>
            <Card
              style={{ minHeight: "700px", maxHeight: "700px" }}
              className="activtyLogShadow"
            >
              <CardHeader className="border-0 voucher-card-head">
                <h3 style={{ color: "#FFF" }} className="mb-0">
                  All vouchers
                </h3>
                <Button
                  className="voucher-create-btn"
                  type="button"
                  onClick={() => setOpen(true)}
                >
                  Create New
                </Button>
              </CardHeader>
              <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
                {vouchers?.map((voucher, index) => (
                  <div
                    className="voucher-data"
                    style={{ backgroundColor: true ? "#F6F9FC" : "#FFEFEF" }}
                  >
                    <div style={{ padding: "15px 18px" }}>
                      <h2 style={{}}>{voucher.voucherName}</h2>

                      <div
                        style={{ marginTop: "-40px" }}
                        className="text-right"
                      >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {/* <DropdownItem href="#pablo" onClick={handleOpen}>
                              Edit
                            </DropdownItem> */}
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) => handleDeleteVoucher(voucher)}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div
                        style={{
                          margin: "10px 0",
                          color: "#9A9A9A",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5 style={{ color: "#9A9A9A", fontSize: "0.8vw" }}>
                          {convertDateFormat(voucher.createdAt)} -{" "}
                          {convertDateFormat(voucher.expiryDate)}
                        </h5>
                        <span
                          className={
                            voucher?.isGlobal ? "globalVoucherPill" : ""
                          }
                        >
                          {voucher?.isGlobal ? "Global" : ""}
                        </span>
                        {/* <span>{console.log({voucher})}</span> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "20px",
                          fontSize: "0.8vw",
                        }}
                      >
                        <span style={{ color: "#9A9A9A", fontWeight: "bold" }}>
                          Price:
                        </span>{" "}
                        <span style={{ color: "#454545", fontWeight: "bold" }}>
                          ${voucher.price}
                        </span>
                        <span style={{ color: "#9A9A9A", fontWeight: "bold" }}>
                          Count:{" "}
                        </span>{" "}
                        <span style={{ color: "#454545", fontWeight: "bold" }}>
                          {voucher.publishTo.length}{" "}
                        </span>
                        <span style={{ color: "#9A9A9A", fontWeight: "bold" }}>
                          Redeemed:{" "}
                        </span>{" "}
                        <span style={{ color: "#454545", fontWeight: "bold" }}>
                          {redeemCount(voucher.publishTo)}{" "}
                        </span>
                        <div
                          style={{
                            cursor: "pointer",
                            float: "right",
                            color: "#FF6700",
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            setVoucherMembers(voucher.publishTo);
                            setSelectedVoucher(voucher);
                            setSelectedVoucherIndex(index);
                          }}
                        >
                          See details
                          <i
                            style={{ marginLeft: "5px" }}
                            className="fas fa-chevron-right"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
          <Col xs={12} md={12} lg={5} xl={5} style={{ minWidth: "50%" }}>
            <Card
              style={{ minHeight: "700px", maxHeight: "700px" }}
              className="shadow"
            >
              <CardHeader className="border-0 voucher-card-head1">
                <h3 className="mb-0">Members</h3>
                {selectedVoucher?.voucherName ? (
                  <div>
                    <div>
                      {selectedVoucher?.isGlobal ? (
                        <Button
                          className="voucher-btn"
                          type="button"
                          onClick={() => {
                            setOpen1(true);
                            makeNewmemberList();
                          }}
                        >
                          Add Member
                        </Button>
                      ) : null}
                    </div>
                    <div>
                      {!selectedVoucher?.isGlobal &&
                      !selectedVoucher?.publishTo[0]?.isRedeemed ? (
                        <Button
                          className="voucher-btn"
                          type="button"
                          onClick={() => {
                            setSplitVoucherModel(true);
                          }}
                        >
                          Split Voucher
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      style={{ fontSize: "0.625vw", fontWeight: 600 }}
                      scope="col"
                    >
                      TUTEE
                    </th>
                    <th
                      style={{ fontSize: "0.625vw", fontWeight: 600 }}
                      scope="col"
                    >
                      STATUS
                    </th>
                    <th
                      style={{ fontSize: "0.625vw", fontWeight: 600 }}
                      scope="col"
                    >
                      EXPIRY DATE
                    </th>
                    <th
                      style={{ fontSize: "0.625vw", fontWeight: 600 }}
                      scope="col"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {voucherMembers?.map((vm, i) => (
                    <tr>
                      <td style={{ maxWidth: "25%" }}>
                        <div style={{ marginTop: "8px" }}>
                          <Link
                            className="mb-0 "
                            style={{ fontSize: "100%" }}
                            to={`/admin/Tutee/${vm?.user?._id}`}
                          >
                            {vm?.user?.name}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            backgroundColor: vm.isRedeemed ? "#12BF0F" : "red",
                            width: vm.isRedeemed
                              ? "80px !important"
                              : "110px !important",
                          }}
                          className={
                            vm.isRedeemed
                              ? "namebutton voucher-table-data"
                              : "namebutton1 voucher-table-data"
                          }
                        >
                          {vm.isRedeemed ? "Redeemed" : "Not Redeemed"}
                        </div>
                      </td>
                      <td>{String(vm.joinDate).substring(0, 10)}</td>
                      <td>
                        {vm?.isGlobal && (
                          <div style={{ margin: "0px 15px" }}>
                            <i
                              style={{ color: "grey", cursor: "pointer" }}
                              className="far fa-trash-alt"
                              onClick={() => {
                                addNewMembers("delete", i);
                                // toast.info("Deleting member...");
                              }}
                            ></i>

                            {/* <button onClick={notify}>Notify!</button> */}
                            <ToastContainer />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>

      <CreateVoucherModal
        err={err}
        isOpen={isOpen}
        setOpen={setOpen}
        setPrice={setPrice}
        setDate={setDate}
        allTutees={allTutees}
        onSelect={onSelect}
        publishTo={publishTo}
        postVoucher={postVoucher}
        selectedTutee={selectedTutee}
        setVoucherName={setVoucherName}
        handleTuteeChange={handleTuteeChange}
        handleSelectChange={handleSelectChange}
      />

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen1}
        toggle={() => setOpen1(!isOpen1)}
      >
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>Add Member Voucher</h1>
          <p style={{ color: "#9A9A9A" }}> {selectedVoucher.voucherName}</p>
        </div>

        <div className="voucher-addMember-data">
          <MultiSelectDropdown
            title="Add User"
            options={newMemberList}
            onSelect={onSelect1}
          />
        </div>

        <div className="modal-footer">
          <Link
            style={{ width: "100%", height: "40px", paddingTop: "8px" }}
            className="modalFooter"
            onClick={() => {
              addNewMembers("add", 0);
              // toast.info("Adding member...");
            }}
          >
            Add Member
          </Link>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        style={{ height: "30vh !important" }}
        isOpen={splitVoucherModel}
        toggle={() => setSplitVoucherModel(!splitVoucherModel)}
      >
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>Split Voucher</h1>
          <p style={{ color: "#9A9A9A" }}>
            {" "}
            {selectedVoucher.voucherName} {selectedVoucher?.price}
          </p>
          <div></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "30px",
                padding: "0px 30px",
              }}
            >
              <span>Value of first voucher</span>
              <input
                value={splitVoucherValueOne}
                type={"number"}
                onChange={handleSplitVoucherValueOne}
              ></input>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "30px",
                padding: "0px 30px",
              }}
            >
              <span>Value of second voucher</span>
              <input
                value={splitVoucherValueTwo}
                type={"number"}
                onChange={handleSplitVoucherValueTwo}
              ></input>
            </div>
          </div>

          <div style={{ marginBottom: "30px" }}>
            <Button
              className="voucher-btn"
              disabled={splitVoucherButtonDisabled || state.isLoading}
              onClick={handleSplitVoucher}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      <ModalEdit
        open={editModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </ModalEdit>
    </>
  );
};

export default Tables;
