import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Redirect } from "react-router-dom"; // <-- Change BrowserRouter to HashRouter
import "assets/css/argon-dashboard-react.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import VerifyEmailScreen from "./views/pages/verifyEmail.js";
import { reactLocalStorage } from "reactjs-localstorage";
import ResetPassword from "views/pages/resetPassword.js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

const user = reactLocalStorage.getObject("userData");
const index = reactLocalStorage.getObject("sidebar-index");
console.log({ index });
const routes = [
  "/admin/index",
  "/admin/leads",
  "/admin/users",
  "/admin/jobPosts",
  "/admin/courses",
  "/admin/biling",
  "/admin/pushNotification",
  "/admin/activityLog",
  "/admin/vouchers",
  "/admin/Member",
  "/admin/index",
];

ReactDOM.render(
  <HashRouter>
    <ToastContainer autoClose={2000} />

    <Route
      exact
      path="/resetPassword/:email/:token"
      render={(props) => <ResetPassword {...props} />}
    />

    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
    <Route
      exact
      path="/verifyEmail/:userId"
      render={(props) => <VerifyEmailScreen {...props} />}
    />

    <Route
      exact
      path="/"
      render={(props) =>
        !isEmpty(user) ? <AdminLayout {...props} /> : <AuthLayout {...props} />
      }
    />

    {/* <Redirect from="/" to={routes[index?.index || 0]} /> */}
  </HashRouter>,
  document.getElementById("root")
);
