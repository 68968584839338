import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { chartExample1 } from "variables/charts";

const RevenueGraph = ({ data = [], labels = [], allData }) => {
  const [state, setState] = useState({
    data: {
      labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Sales",
          data: [25, 20, 30, 22, 17, 29],
          maxBarThickness: 10,
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
  });

  const updateDataOfGraph = () => {
    setState((prev) => ({
      ...prev,
      data: {
        labels: labels,
        datasets: [
          {
            label: "Sales",
            data: data,
            maxBarThickness: 10,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value) {
                  if (!(value % 10)) {
                    return "$" + value;
                    // return value;
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (item, data) {
              var label = item[0]?.label || "";
              var yLabel = item[0].yLabel;

              return `${label} ($${yLabel})`;
            },
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || "";
              // var yLabel = item.yLabel;
              var content = "";

              if (data.datasets.length > 1) {
                content += label;
              }

              let selectedData = allData.filter(
                (record) => record.month === item.label
              );

              if (selectedData)
                return [
                  `Total Bill: $${selectedData[0]?.totalBill || 0}\n`,
                  `Paid: $${selectedData[0]?.paid || 0}`,
                  `Tutor Salary: $${selectedData[0]?.totalSalary || 0}\n`,
                  // `Total Revenue : $${yLabel}\n`,
                ];
            },
          },
        },
      },
    }));
  };

  useEffect(() => {
    updateDataOfGraph();
  }, [data]);

  return (
    // <div>
    <Line
      data={state.data}
      options={state.options}
      getDatasetAtEvent={(e) => console.log(e)}
    />
    // </div>
  );
};

export default RevenueGraph;
