/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  // ReactLoading,
  Modal,
  Table,
  Container,
  FormGroup,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  UncontrolledTooltip,
  // InputGroupAddon,
  Input,
  Row,
  Col,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { reactLocalStorage } from "reactjs-localstorage";
import api from "../../../services/api";
import { CSVLink, CSVDownload } from "react-csv";
import "./index.css";

// Mui Components
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import Avatar from "@mui/material/Avatar";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { doc, setDoc } from "firebase/firestore";
import { db } from "../Chat/firebase-config";

// MUI Button
import { Button as MuiButton } from "@mui/material";
import { async } from "@firebase/util";

import ReactLoading from "react-loading";
import { get } from "jquery";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#FF8A3C",
      darker: "#FF8A3C",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});
const TutorTables = () => {
  // const [searchText1, setSearchText1] = useState("");
  // const [allJobs, setAllJobs] = useState([]);
  // const [rowOpen, setRowOpen] = useState(false);
  const [salaries, setSalaries] = useState([]);
  const [date, setDate] = useState("");
  // const [loggedUser, setLoggedInUser] = useState([]);
  const [value, setValue] = useState(new Date());
  const [searchText2, setSearchText2] = useState("");
  // const [months, setMonths] = useState([
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ]);
  const today2 = new Date();
  const [selectedMonth, setSelectedMonth] = useState(0);
  // const [stats, setStats] = useState({
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     {
  //       label: "Tuition Stats",
  //       data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  //       maxBarThickness: 10,
  //     },
  //   ],
  // });
  const size = useWindowSize();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthSelected = monthNames[selectedMonth];

  function incrementMonth() {
    const newMonth = selectedMonth + 1;
    console.log(selectedMonth, "selectedMonth");
    console.log(newMonth);
    if (newMonth <= 11) {
      setSelectedMonth(newMonth);
      getSalaries(newMonth);
    }
  }
  function decrementMonth() {
    const oldMonth = selectedMonth - 1;
    console.log(selectedMonth, "selectedMonth");
    console.log(oldMonth);
    if (oldMonth >= 0) {
      setSelectedMonth(oldMonth);
      getSalaries(oldMonth);
    }
  }

  async function getSalaries(selectedMonth) {
    try {
      const salaries = await api.getSalaries(`?month=${selectedMonth}`);
      console.log(salaries, "fron function");
      setSalaries(salaries?.data);
    } catch (e) {
      console.log(e.response);
    }
  }

  function getTotalSalaries(selectedMonth) {
    try {
      console.log(selectedMonth, "from getTotalSalaries ");
      let addedAmount = 0;
      for (let i = 0; i < salaries?.length; i++) {
        addedAmount = addedAmount + salaries[i]?.totalAmonut;
      }
      console.log(addedAmount, "addedAmount");
      return addedAmount;
    } catch (e) {
      console.log(e.response);
    }
  }
  // async function getStats() {
  //   try {
  //     const stats = await api.getTuitionStats();
  //     setStats(stats.data);
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // }

  function getMonth() {
    try {
      const today = new Date();
      setSelectedMonth(today.getMonth());
      const val = value.getMonth();
      setValue(val);
    } catch (e) {
      e.response;
    }
  }
  useEffect(async () => {
    // const user = reactLocalStorage.getObject("userData");
    // setLoggedInUser(user);
    // getMonth();
    setSalaries();
    // getStats();
  }, [selectedMonth]);
  useEffect(async () => {
    // const user = reactLocalStorage.getObject("userData");
    // setLoggedInUser(user);
    // getMonth();
    getMonth();
    const salaries = await api.getSalaries(`?month=${selectedMonth}`);
    console.log(salaries, "first render");
    setSalaries(salaries?.data);

    // getStats();
  }, []);

  return (
    <Row style={{ marginTop: "20px" }}>
      {/* <Col xs={12} md={12} lg={12} xl={8} style={{width: "100%"}}> */}
      <div className="col ts-col">
        <Card className="shadow card-shadow">
          <CardHeader className="border-0 card-header">
            <div className="tutor-salries-tag">
              <h3
                className="mb-0"
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                Tutor Salries For{" "}
              </h3>
              <div className="tutor-salries-data">
                <Input onChange={(e) => setDate(e.target.value)} type="date" />

                {/* <ThemeProvider theme={theme}>
                  <MuiButton
                    variant="outlined"
                    color="primary"
                    onClick={() => decrementMonth()}
                    style={{ height: "100%" }}
                  >
                    {"<"}
                  </MuiButton>
                  <MuiButton
                    variant="outlined"
                    color="primary"
                    onClick="disable"
                    style={{ height: "100%" }}
                  >
                    {monthSelected}
                  </MuiButton>
                  <MuiButton
                    variant="outlined"
                    color="primary"
                    onClick={() => incrementMonth()}
                    style={{ height: "100%" }}
                  >
                    {">"}
                  </MuiButton>
                </ThemeProvider> */}
              </div>
            </div>
            <div className="tutor-salries-cont">
              <ThemeProvider theme={theme}>
                <MuiButton
                  variant="outlined"
                  color="primary"
                  className="mui-csvbtn"
                >
                  {(salaries?.csvData?.length && (
                    <CSVLink style={{ color: "#fff" }} data={salaries?.csvData}>
                      <i className="fas fa-download">Export CSV</i>
                    </CSVLink>
                  )) || <i className="fas fa-download">Export CSV</i>}
                </MuiButton>
              </ThemeProvider>
              {/* <Button className="tutor-salries-btn"
                    type="button"
                  >
                    {salaries?.csvData?.length && (
                      <CSVLink
                        style={{ color: "#fff" }}
                        data={salaries?.csvData}
                      >
                        <i className="fas fa-download">Export CSV</i>
                      </CSVLink>) || <i className="fas fa-download">Export CSV</i>}
                  </Button> */}
              <div
                onChange={(e) => setSearchText2(e.target.value)}
                className={size.width > 840 ? "jobPosting-search" : "main2"}
                style={size.width > 840 ? { width: "50%" } : {}}
              >
                {/* <div className="form-group has-search dont-remove-this">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Tutee"
                  />
                </div> */}
                <TextField
                  id="search"
                  type="search"
                  label="Search Tutee"
                  // value={searchTerm}
                  // onChange={handleChange}
                  // sx={{ width: 160 }}
                  sx={{
                    width: { sm: 160 },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <Table
            style={{ minHeight: "15px", maxHeight: "300px" }}
            className="align-items-center table-flush"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  TUTOR
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  BANK INFO
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  MONTH
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Tuitions
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Total SALARY
                </th>
              </tr>
            </thead>
            {salaries?.length ? (
              <tbody>
                {/* {console.log(salaries,"salariessalariessalaries")} */}
                {salaries
                  ?.filter((bill) => {
                    if (searchText2 === "") {
                      return bill;
                    } else if (
                      bill?.tutor[0].name
                        .toLowerCase()
                        .includes(searchText2.toLowerCase())
                    ) {
                      return bill;
                    } else if (
                      bill?.bankDetails[0]?.bankName
                        .toLowerCase()
                        .includes(searchText2.toLowerCase())
                    ) {
                      return bill;
                    } else if (
                      bill?.tutorId
                        .toLowerCase()
                        .includes(searchText2.toLowerCase())
                    ) {
                      return bill;
                    }
                  })
                  .map((bill) => (
                    <tr key={bill?.tutorId}>
                      {console.log(bill)}
                      <td>
                        {bill?.tutorName}
                        <Link to={`/admin/Tutor/${bill?.tutorId}`}></Link>
                      </td>
                      <td>{bill?.bankDetails[0]?.bankName}</td>
                      <td style={{ fontWeight: "bold" }}>{monthSelected}</td>
                      <td>{bill.totalTutions}</td>
                      <td>
                        ${bill.totalAmonut}
                        {console.log(bill, "bill")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : null}
          </Table>
          <CardFooter style={{ backgroundColor: "#F6F9FC" }} className="py-1">
            <div style={{ marginLeft: "50%", marginTop: "10px" }}>
              <h3 className="tutor-salaries-foot">
                Total Tutor Salaries:{" "}
                <span style={{ fontSize: "1.1vw", color: "#454545" }}>
                  {/* {getTotalSalaries(selectedMonth)} */}
                  {/* {console.log(salaries,'salaries from footer')} */}
                  {/* {console.log(selectedMonth,'selectedMonth from footer')} */}
                </span>
              </h3>
            </div>
          </CardFooter>
        </Card>
      </div>
      {/* </Col> */}
      {/* <Col xs={12} md={12} lg={12} xl={4} >
            <Card className="shadow" style={{ marginLeft: "-25px" }}>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      OVERVIEW
                    </h6>
                    <h2 className="mb-0">Tuitions started</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody style={{ height: "100%" }}>
                <div className="chart">
                  <Bar
                    data={stats}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
    </Row>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default TutorTables;
