import "./index.css";

const JobStatusView = ({ jobStatus = 1, title = "Posted" }) => {
  return (
    <>
      <div
        className="jobStatus"
        style={{
          color: jobStatus === 1 ? "#000" : "#fff",
          backgroundColor:
            jobStatus === 1 ? "#e9ecef" : jobStatus === 5 ? "red" : "#12BF0F",
        }}
      >
        <div>{title}</div>
      </div>
    </>
  );
};

export const JobStatusEnum = (jobStatus) => {
  let dataForStatus = {};
  switch (jobStatus) {
    case 1:
      dataForStatus = {
        backgroundColor: "rgb(233, 236, 239)",
        textColor: "rgb(0, 0, 0)",
        title: "Posted",
        view: <JobStatusView jobStatus={jobStatus} title="Posted" />,
      };
      return dataForStatus;
    case 2:
      dataForStatus = {
        backgroundColor: "rgb(18, 191, 15)",
        textColor: "rgb(0, 0, 0)",
        title: "Ongoing",
        view: <JobStatusView jobStatus={jobStatus} title="Ongoing" />,
      };
      return dataForStatus;
    case 3:
      dataForStatus = {
        backgroundColor: "rgb(18, 191, 15)",
        textColor: "rgb(0, 0, 0)",
        title: "Completed",
        view: <JobStatusView jobStatus={jobStatus} title="Completed" />,
      };
      return dataForStatus;
    case 4:
      dataForStatus = {
        backgroundColor: "rgb(233, 236, 239)",
        textColor: "rgb(0, 0, 0)",
        title: "Terminate Requested",
        view: (
          <JobStatusView jobStatus={jobStatus} title="Terminate Requested" />
        ),
      };
      return dataForStatus;
    case 5:
      dataForStatus = {
        backgroundColor: "red",
        textColor: "#fff",
        title: "Terminated",
        view: <JobStatusView jobStatus={jobStatus} title="Terminated" />,
      };
      return dataForStatus;

    default:
      return <div>Pending</div>;
  }
};

export const getCurrentUserInfo = () => {
  return JSON.parse(localStorage.getItem("userData"));
};
