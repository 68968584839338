import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Modal,
  Button,
  FormGroup,
  Input,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { Link } from "react-router-dom";

import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import api from "../../services/api";
import { reactLocalStorage } from "reactjs-localstorage";
import "./index.css";
import { JobStatusEnum } from "helpers/common-helper";

export default class DemoApp extends React.Component {
  state = {
    modal: false,
    weekendsVisible: true,
    currentEvents: [],
    isOpen: false,
    allEvents: this.props.schedule,
    isOpen: false,
    oldDate: "",
    newDate: "",
    id: "",
    newData: "",
    jobId: "",
    logginUser: {},
    jobPostColor: "",
    event: {},
  };

  convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split("");
    var ddChars = dd.split("");

    return (
      yyyy +
      "-" +
      (mmChars[1] ? mm : "0" + mmChars[0]) +
      "-" +
      (ddChars[1] ? dd : "0" + ddChars[0])
    );
  }
  async reschedule(data, newDate, title, color, oldDate, jobId) {
    const newData = {
      ...data,
      date: newDate,
      rescheduleRef: data._id,
      title,
      color,
    };
    delete newData._id;
    delete newData.createdAt;
    delete newData.updatedAt;
    // data.date = newDate;
    this.setState({ newDate, oldDate, id: data._id, newData, jobId });
    this.setState({ isOpen: true });
  }

  convertDateFormat(date) {
    var today = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      today.getDate() +
      " " +
      monthNames[today.getMonth()] +
      ", " +
      today.getFullYear()
    );
  }
  getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async handleReschedule() {
    await api.reschedule(this.state.id, this.state.newData);
    const nod = this.convertDateFormat(this.state.oldDate);
    const nnd = this.convertDateFormat(this.state.newDate);
    const data = {
      adminId: this.state.logginUser._id,
      // userId: userId,
      jobId: this.state.jobId,
      message: `${nod} to ${nnd}`,
      // links: links,
      activityType: "Reschedule",
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
    this.setState({ isOpen: false });
    this.props.closeCalendar();
  }
  async componentDidMount() {
    const user = await reactLocalStorage.getObject("userData");
    this.setState({ logginUser: user });
  }
  toggle = () => {
    this.setState({ modal: !this?.state?.modal });
  };
  handleEventClick = ({ event, el }) => {
    this.toggle();
    this.setState({ event });
    console.log({ event, asd: event.title, color: event.start });
  };

  render() {
    console.log("SCHEDULE:", { state: this.state }, this.props),
      this.state?.event?.extendedProps;
    return (
      <CardBody>
        <div style={{ height: "100%" }}>
          <FullCalendar
            height={"100%"}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "",
            }}
            initialView="dayGridMonth"
            events={this.props.schedule}
            editable={this.props.editable}
            selectable={false}
            // eventBorderColor={this.props.jobPostColor ? this.props.jobPostColor : "#5e72e4"}
            // eventBackgroundColor={this.props.jobPostColor ? this.props.jobPostColor : "#000"}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={this.state.weekendsVisible}
            select={this.handleDateSelect}
            eventChange={(e) => {
              this.reschedule(
                e.event._def.extendedProps,
                this.convertDate(new Date(e.event._instance.range.start)),
                e.event._def.title,
                e.event._def.ui.backgroundColor,
                this.convertDate(new Date(e.oldEvent._instance.range.start)),
                e.oldEvent._def.extendedProps.jobId
              );
            }}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
                      eventAdd={function(){}}
                      eventRemove={function(){}}
                      */
          />
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Event details</h1>
          </ModalHeader>
          <ModalBody>
            <EventInfo event={this.state?.event} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.isOpen}
          size="lg"
          // style={{ maxWidth: "1200px", width: "100%" }}
          toggle={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <CardHeader className="border-0">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ marginBottom: "-2px" }}>
                {" "}
                Do you want to reschedule this class?{" "}
              </h3>
              <i
                style={{
                  marginLeft: "270px",
                  paddingTop: "3px",
                  color: "#9A9A9A",
                }}
                onClick={() => this.setState({ isOpen: false })}
                className="fas fa-times"
              ></i>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h5>From date</h5>
                <div>
                  <FormGroup className="mb-0">
                    <Input type="text" disabled value={this.state.oldDate} />
                  </FormGroup>
                </div>
              </div>
              <div
                style={{
                  marginTop: "25px",
                  marginLeft: "50px",
                  marginRight: "50px",
                }}
              >
                <i
                  style={{ color: "#9A9A9A" }}
                  className="fas fa-arrow-right"
                ></i>
              </div>
              <div>
                <h5>From date</h5>
                <div>
                  <FormGroup className="mb-0">
                    <Input type="text" disabled value={this.state.newDate} />
                  </FormGroup>
                </div>
              </div>
            </div>
          </CardBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#F5365C",
                borderColor: "#F5365C",
              }}
              onClick={() => this.handleReschedule()}
              type="button"
            >
              Reschedule
            </Button>
          </div>
        </Modal>
      </CardBody>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleDateSelect = (selectInfo) => {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  // handleEventClick = (clickInfo) => {
  //   if (
  //     alert(
  //       `Are you sure you want to delete the event '${clickInfo.event.title}'`
  //     )
  //   ) {
  //     clickInfo.event.remove();
  //   }
  // };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };
}

function renderEventContent(eventInfo) {
  return (
    <ul
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 0px 0px 0px",
        width: "100%",
      }}
    >
      <b>
        {formatDate(eventInfo.event.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
    </ul>
  );
}

const EventInfo = ({ event }) => {
  const getDateFormate = (dateObject) => {
    const day = dateObject.getDate();
    const monthIndex = dateObject.getMonth();
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();

    // Month names array (you can customize it according to your needs)
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date string
    const fullDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return fullDate;
  };

  const eventInfo = event?.extendedProps;

  if (eventInfo?.reschedule) {
    return (
      <>
        <div>
          <h3>{`${eventInfo?.courseName} - ${eventInfo?.courseLvl}  ( ${
            JobStatusEnum(eventInfo?.jobStatus).title
          } )`}</h3>
          <span className="prepaidTag">
            <span className="cut-off-text">
              {getDateFormate(new Date(eventInfo?.oldDate))}
            </span>
            <span>&nbsp;(Reschedule)</span>
            {eventInfo?.isPrePaid && <div className="tbl-btn">Prepaid</div>}
          </span>

          <span className="cut-off-text">{eventInfo?.time}</span>
        </div>

        <div>
          <h4>Current Date and time</h4>
          <span className="prepaidTag">
            {getDateFormate(new Date(eventInfo.newDate))}
            {eventInfo?.isPrePaid && <div className="tbl-btn">Prepaid</div>}
          </span>

          <span>{eventInfo?.newTime}</span>
        </div>
      </>
    );
  } else
    return (
      <>
        <h3>{`${eventInfo?.courseName} - ${eventInfo?.courseLvl}  ( ${
          JobStatusEnum(eventInfo?.jobStatus).title
        } )`}</h3>
        <span className="prepaidTag">
          {getDateFormate(new Date(event.start))}
          {eventInfo?.isPrePaid && <div className="tbl-btn">Prepaid</div>}
        </span>

        <span>{eventInfo?.time}</span>
      </>
    );
};

// function renderSidebarEvent(event) {
//     return (
//         <li key={event.id}>
//             <b>{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
//             <i>{event.title}</i>
//         </li>
//     )
// }
